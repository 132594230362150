.map-screenshot-popup-edit {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-width: 524px;
    width: 524px;
    padding: 4px;
    font-family: 'Noto Sans Armenian', sans-serif;
}

.map-screenshot-popup-edit__header {
    display: flex;
    flex-direction: row;
    align-items:  flex-start;
    justify-content: space-between;
}

.map-screenshot-popup-edit__header-buttons {
    min-width: 50px;
    min-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
}


.map-screenshot-popup-edit__actions {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    margin-top: 15px;
}

.map-screenshot-popup-edit__action {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 10px;
    cursor: pointer;
}

.map-screenshot-popup-edit__icon-container {
    background-color: #2c476c1a;
    width: 34px;
    height: 34px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-screenshot-popup-edit__icon-minus {
    width: 25px;
    height: 14px;
    scale: .8;
}

.map-screenshot-popup-edit__icon {
    width: 18px;
    height: 19px;
    scale: .8;
}


.map-screenshot-popup-edit__description {
    margin: 15px 0;
    padding-right: 10px;
    width: 516px;
    max-height: 124px;
    overflow-y: auto;
    overflow-x: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    color: #2c476c;
    font-weight: 400;
}

.map-screenshot-popup-edit__header-title {
    font-weight: bold;
    /*margin-left: 16px;*/
    font-size: 18px;
    color: #2C476C;
    text-transform: uppercase;
    letter-spacing: 1px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}


@media (min-width: 3000px) {
    .map-screenshot-popup-edit {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 766px;
        max-width: 766px;
        padding: 20px;
    }

    .map-screenshot-popup-edit__header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    .map-screenshot-popup-edit__header-buttons {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .map-screenshot-popup-edit__action {
        display: flex;
        align-items: center;
        width: max-content;
        gap: 20px;
        cursor: pointer;
    }

    .map-screenshot-popup-edit__icon-container {
        background-color: #2c476c1a;
        width: 78px;
        height: 78px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .map-screenshot-popup-edit__icon-minus {
        width: 46px;
        height: 25px;
    }

    .map-screenshot-popup-edit__icon {
        width: 26px;
        height: 26px;
    }

    .map-screenshot-popup-edit__description {
        margin: 30px 0;
        width: 960px;
        max-height: 220px;
    }
    .map-screenshot-popup-edit__header-title {
        font-weight: bold;
        /*margin-left: 16px;*/
        font-size: 38px;
        line-height: 46px;
        color: #2C476C;
        text-transform: uppercase;
        letter-spacing: 2px
    }
}

