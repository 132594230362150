.language-selector {
    position: relative;
}

.dropdown-button {
    cursor: pointer;
    margin: 15px 20px 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-menu {
    flex-direction: column;
    margin-top: 15px;
    position: absolute;
    top: 30px;
    border: 1px solid #FFFFFF8F;
    left: calc(50% - 18px);
    padding: 8px 10px;
    justify-content: space-between;
    height: 80px;
    border-radius: 8px;
    background: rgba(217,217,217, 0.25);
    display: none
}

.dropdown-menu img {
    width: 23px;
    height: 23px;
}
.open {
    display: flex;
}

.dropdown-item {
    cursor: pointer;
    margin-top: 5px;
}

@media screen and (max-width: 1024px) {
    .dropdown-menu {
        flex-direction: column;
        margin-top: 0;
        position: absolute;
        top: 47px;
        border: 1px solid #FFFFFF8F;
        left: -7px;
        padding: 0;
        justify-content: space-between;
        width: 56px;
        height: 108px;
        border-radius: 8px;
        background: #fff;
    }

    .dropdown-menu.open {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .dropdown-menu.open {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropdown-menu.open .dropdown-item {
        border-bottom: 1px solid #EAEAEA
    }

    .dropdown-menu.open .dropdown-item:last-child {
        border-bottom: none;
    }

    .dropdown-item {
        width: 44px;
        height: 44px;
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropdown-menu .dropdown-item img {
        width: 32px;
        max-width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
    }
}

@media (max-width: 582px) {
    .dropdown-button {
        margin: 15px 0 0;
    }
}
