.card-body__dynamic-new-tab.dynamic-new-tab{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: max-content;
}
.dynamic-new-tab__btn{
    cursor: pointer;
}
.dynamic-new-tab__btn svg *{
    stroke: var(--dynamic-screenshot-color);
}
.card-body__dynamic-new-tab dynamic-new-tab a{
    height: 100%;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .card-body__dynamic-new-tab.dynamic-new-tab{
        height: 17px;
        align-items: end;
    }
    .card-body__dynamic-new-tab.dynamic-new-tab svg{
        width: 15px;
        height: 15px;
    }
}
