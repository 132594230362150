.account .main__tab.active {
    color: var(--dynamic-screenshot-color); /* Changes text color */
}

.account .main__tab.active .main__tab-btn svg {
    fill: var(--dynamic-screenshot-color); /* Changes icon color if SVG */
}

.account .main__tab.activeTitle {
    color: var(--dynamic-screenshot-color); /* Changes specific title color */
}

#micro-article-icon {
    fill: none !important;
}

.headerWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 14px;
}

.top-btns {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 17px 17px 17px 90px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
}

.top-btns .top-btns-left-bar,
.top-btns .top-btns-right-bar {
    gap: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.top-btns .top-btns-left-bar {
    padding-left: 7px;
}

@media screen and (min-width: 1024px) and (max-width: 1800px) {
    .top-btns {
        margin-bottom: 34px;
    }
}

@media screen and (max-width: 1024px) {
    .headerWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 14px;
    }

    .top-btns {
        display: flex;
        align-items: center;
        position: unset;
        min-height: 72px;
        width: 100%;
        top: 0;
        padding: 0;
        height: fit-content;
    }

    .top-btns .top-btns-left-bar,
    .top-btns .top-btns-right-bar {
        align-items: center;
    }

    .top-btns .main__tab-btn {
        min-width: 44px;
        min-height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 0 7px 26px 0 #2C476C1A;
    }

    .top-btns .main__tab-btn svg {
        width: 24px;
        max-width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
        max-height: 24px;
    }
}
