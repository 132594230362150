.gridContainer {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    background: #2c476c;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));
    grid-gap: 10px;
    box-sizing: border-box;
}

@media screen and (max-height: 800px) {
    .gridContainer {
        margin-bottom: 20px;
    }
}

@media (max-width: 1280px) {
    .gridContainer {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
    }
}

@media (max-width: 1180px) {
    .gridContainer {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
    }
}

@media (max-width: 900px) {
    .gridContainer {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
}


@media (max-width: 550px) {
    .gridContainer {
        grid-template-columns: repeat(1, minmax(150px, 1fr));
    }
}
