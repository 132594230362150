.fontStyles {
    font-size: 14px !important;
    font-family: 'Noto Sans Armenian', sans-serif !important;
}

.microArticle_form .ant-form-item {
    margin-bottom: 17px;
}

.full-screen-form-popup {
    width: 100dvw;
    height: 100dvh;
    position: absolute;
    top: 0;
    right: 0;
}

/* Skeleton loading styles */
.skeletonContainer {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.headerSkeletonWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
}

.headerSkeleton {
    display: flex;
    align-items: center;
    gap: 8px;
}

.headerSkeletonInitials {
    border-radius: 20px;
    max-width: 31px;
    max-height: 23px;
    min-width: 31px;
    min-height: 23px;
}

.headerSkeletonInitials > .ant-skeleton-avatar.ant-skeleton-avatar-square {
    border-radius: 20px;
    max-width: 31px;
    max-height: 23px;
    min-width: 31px;
    min-height: 23px;
}

.carouselSkeleton {
    width: 100%;
    height: 200px;
}

.carouselSkeleton > .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
    width: 100% !important;
    height: 100% !important;
}

.descriptionSkeleton {
    width: 100%;
    height: 100px;
}

.buttonsSkeleton {
    display: flex;
    gap: 8px;
}

.buttonsSkeleton > .skeletonButton {
    max-width: 35px;
    width: 35px;
    height: 35px;
}

.buttonsSkeleton > .skeletonButton > span {
    max-width: 35px !important;
    min-width: 35px !important;
    width: 35px !important;
    max-height: 35px !important;
    min-height: 35px !important;
    height: 35px !important;
}

.carouselSkeleton {
    width: 100%;
    height: 290px;
}

.carouselSkeleton .ant-skeleton-image {
    width: 100%;
    height: 100%;
}

.descriptionSkeleton .ant-skeleton-paragraph > li {
    width: 80%;
}

.full-screen-form-popup .ant-input-data-count {
    margin-right: 0 !important;
    color: #2C476C52 !important;
}

@media (max-width: 1024px) {
    .fontStyles.microArticleFormPopupContainer label {
        font-size: 14px !important;
        font-family: 'Noto Sans Armenian', sans-serif !important;
        font-weight: 400 !important;
        line-height: 32px !important;
        letter-spacing: 1px !important;
        margin: 0 !important;
        padding: 0 !important;
        text-align: left !important;
        text-underline-position: from-font !important;
        text-decoration-skip-ink: none !important;
    }

    .headerSkeletonWrapper {
        display: flex;
        justify-content: space-between;
        padding: 12px;
    }

    .microArticle_form {
        margin-bottom: 68px !important;
    }

    .microArticle_form label {
        user-select: none !important;
    }

    .custom-popover {
        background-color: transparent !important;
        box-shadow: none;
    }

    .buttonsSkeleton {
        display: flex;
        justify-content: right;
        gap: 8px;
    }
}
