.cardMinimized {
    max-width: 480px;
    min-width: 480px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin: 10px;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.cardMinimized .ant-card .ant-card-body {
    padding: 0 !important;
}

.minimizedHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 68px;
    border: none;
}

.minimizedImageWrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 68px;
    min-height: 100%;
    gap: 10px;
}

.minimizedImage {
    min-height: 68px;
    width: 110px;
    max-width: 110px;
    min-width: 110px;
    object-fit: cover;
    border-radius: 0;
}

.minimizedTitleWrapper {
    height: 68px;
    display: block; /* Ensures the text can flow properly */
    align-items: flex-start;
    overflow: hidden; /* Prevents text from overflowing horizontally */
}

.minimizedTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Limit to 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 1.15;
    margin: 0;
    padding: 0;
    width: 100%; /* Ensures it fills the container width */
    min-height: 68px;
}

.minimizedButtons {
    display: flex;
    align-items: flex-start;
    min-height: 100%;
}

.card {
    max-width: 610px;
    min-width: 610px;
    margin: 10px;
    height: fit-content;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.card .ant-card .ant-card-head {
    border-bottom: none !important;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-grow: 1;
}

.titleText {
    min-height: 60px;
    flex-grow: 1;
    letter-spacing: 2px;
    max-height: 100%;
    text-overflow: ellipsis;
    word-wrap: break-word; /* Ensures text wraps in case of long titles */
    white-space: normal;
    user-select: none;
}

.buttons {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    margin: .5em 0;
    min-height: 100%;
}

.customIcon {
    width: 18px;
    height: 18px;
}

.toggleButton,
.closeButton {
    padding: 4px;
    color: #2C476C;
}

.nameInitials {
    transform: translateY(-3px);
    border-radius: 20px;
    letter-spacing: 0;
    max-width: 31px;
    max-height: 23px;
    min-width: 31px;
    min-height: 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    color: #2C476C;
    background: #F3BFDA;
    margin-right: 8px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
    flex-grow: 1;
    font-size: 1.2rem;
    font-weight: bold;
}

.closeButton {
    font-size: 16px;
    color: #2C476C;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.content {
    padding: 0 16px 16px 16px;
}

/* Container for the Carousel */
.carousel {
    /*margin-bottom: 6px;*/
    position: relative;
    margin: 0 -2px 6px;
}

.carousel :global .slick-slide {
    padding: 0 2px;
}

.carousel :global .slick-list {
    /*margin: 0 -5px;*/
}

.carouselContainer {
    position: relative;
    cursor: pointer; /* Default cursor */
}

.carouselContainer.grabbing {
    cursor: grabbing; /* Cursor during dragging */
}

.dotPagination {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Space between dots and description */
}

.carouselItem {
    text-align: center;
    position: relative;
}

.image {
    width: 100%;
    border-radius: 8px;
    max-height: 320px;
    min-height: 320px;
    object-fit: contain;
}

.nonClickable::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3); /* Полупрозрачный наложение */
    pointer-events: none; /* Позволяет событиям проходить сквозь наложение */
}

.hidden {
    visibility: hidden;
}

.loadingWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.caption {
    margin-top: 8px;
    font-size: 0.9rem;
    color: #666;
}

.description {
    height: 110px;
    min-height: 110px;
    max-height: 110px; /* Limit the height to 110px */
    overflow-y: auto; /* Make it scrollable if content exceeds 110px */
    font-weight: 400; /* Set font weight to 400 */
    font-size: 16px; /* Set font size to 16px */
    line-height: 21px; /* Set line height to 21px */
    padding-right: 10px; /* Optional: Add padding to avoid scrollbars overlapping text */
}

.sectionTitle {
    font-size: 1rem;
    margin-bottom: 8px;
}

.paragraph {
    font-size: 0.9rem;
    line-height: 1.5;
}

.actionButtons {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.carouselItem img, .carouselItem iframe {
    pointer-events: none;
    user-select: none;
}
@media (max-width: 1540px) {
    .card, .cardMinimized {
        transform: scale(0.8) translateZ(0);
        will-change: transform;
        transform-origin: top right;
        z-index: 99999;
        max-height: fit-content;
    }

}
