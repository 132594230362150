@media (max-width: 1024px) {
    .form-checkbox {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
        font-family: 'Noto Sans Armenian', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 1px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    .form-checkbox .ant-checkbox-inner,
    .form-checkbox .ant-checkbox-input {
        width: 28px !important;
        height: 28px !important;
        border-radius: 10px !important;
    }

    .form-checkbox .ant-checkbox-inner:after {
        inset-inline-start: 34% !important;
        transform: rotate(45deg) scale(1.4) translate(-50%,-50%) !important;
        top: 56% !important;
    }
}
