/* src/components/CustomColorPicker/CustomColorPicker.module.css */

.colorPickerPopoverWrapper {
    margin-left: 20px;
    margin-top: 10px;
}

.colorPickerPopover {
    padding: 0 10px 10px 10px;
    max-width: 205px;
}

.iconButtonsWrapper {
    display: flex;
    gap: 0;
}

.iconButtonsWrapper .ant-space-item {
    display: flex;
    justify-content: end;
    align-items: center;
}

.iconButtonLight,
.iconButtonDark {
    margin-left: 6px;
    margin-bottom: 6px;
    width: 40px;
}

.iconButtonLight:hover {
    color: #fff !important;
}

.iconButtonDark:hover {
    color: #000 !important;
}

.iconButtonDark {
    margin-right: 0;
}

.listItem {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
}

.coloredCircleButton {
    background: conic-gradient(from 180deg, green, yellow, red, blue, green) !important;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}


.colorSquare {
    width: 16px; /* Adjust size as needed */
    height: 16px; /* Adjust size as needed */
    cursor: pointer;
    border: 0;
    transition: outline 0.2s ease-in-out;
    box-sizing: border-box; /* Ensure the outline doesn't affect the size */
    display: flex;
    align-items: center;
    justify-content: center;
}

.colorSquare:hover {
    border: 2px solid white;
}

@media (max-width: 1024px) {
    .gradientColorButtonWrapper {
        position: sticky;
        z-index: 10;
        top: 0;
        left: -56px;
        width: 56px;
        height: 56px;
        min-width: 56px;
        min-height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 0 0 16px 0;
    }

    .colorPickerPopover {
        padding: 0 10px 10px 10px;
        max-width: 307px;
    }

    .colorSquare {
        width: 24px; /* Adjust size as needed */
        height: 24px; /* Adjust size as needed */
        cursor: pointer;
        border: 0;
        transition: outline 0.2s ease-in-out;
        box-sizing: border-box; /* Ensure the outline doesn't affect the size */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .coloredCircleButton {
        width: 32px;
        height: 32px;
    }

    .colorPickerPopoverWrapper {
        margin: 0;
    }
}
