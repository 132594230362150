@font-face {
  font-family: "Noto Sans Armenian";
  src: url("../../assets/fonts/NotoSansArmenian-Medium.woff2") format("woff2"),
    url("../../assets/fonts/NotoSansArmenian-Medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.marker{
  width:30px;
  height:30px;
  top:0; bottom:0; left:0;right:0;
  overflow:hidden;
  margin:auto;
  z-index: 1;
  background-color: #0000;
  transform: translate(252px, 151px) translate(-50%, -50%) translate(0px, 0px);
  border: 1px solid red;
  border-radius: 50%;
  animation: circle 6s linear infinite;
}
@keyframes circle{
  0%{
    transform:rotate(0deg)
    translate(-20px)
    rotate(0deg);

  }
  100%{
    transform:rotate(360deg)
    translate(-20px)
    rotate(-360deg);
  }
}
.loading__overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  cursor: wait;
}
.persons {
  --theme-color: #d59c00;
}

.events {
  --theme-color: #ce7c20;
}

.places {
  --theme-color: #db487f;
}

.fossils {
  --theme-color: #5ba726;
}

.signs {
  --theme-color: #c12e2e;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.map-boby {
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.map-boby:has(#svgsvg):after {
  content: "";
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
}

@media screen and (min-width: 900px) and (max-width: 1800px) {
  .map-boby .main,
  .map-boby .header {
    /*transform: scale(0.8);*/
    /* zoom: 70%; */
  }

  .main__subitem-label {
    /* left: calc(100vw/4.5) !important */
  }

  .category-filters {
    right: 4px !important;
  }

  .right-side {
    bottom: 10vh !important;
  }
}

.opacity-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #00000012;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.9px;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 33, 36, 0.6);
}

.with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.with-icon::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
}

.eye {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.eye::before {
  content: "";
  display: block;
  width: 22px;
  height: 16px;
  opacity: 0.5;
  background: url("../../assets/imgs/eye.svg") no-repeat center/contain;
}

.eye::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -4px;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ff0000;
}

.show-checkbox:checked + .eye::before {
  opacity: 1;
}

.show-checkbox:checked + .eye::after {
  content: none;
}

.main {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-height: calc(100vh - 124px);
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /*z-index: 99;*/
}

.main > * {
  pointer-events: all;
}
.category_menu_button {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  margin-top: 30px;
  left: 14px;
  top: 25px !important;
}
.category_menu_button  *  {
  font-family: 'Noto Sans Armenian','sans-serif'!important;
}
.main__tabs-wrapper {
  position: relative;
  overflow: hidden;
  height: 0;
  gap: calc(100vh / 369.94);
  display: flex;
  flex-direction: column;
}

.main__tabs-wrapper.active {
  /*height: 526px;*/
  height: fit-content;
  overflow: unset;
}

ul.main__tabs.left-side__tabs {
  gap: calc(100vh / 369.94);
  display: flex;
  flex-direction: column;
}

ul.main__tabs.left-side__tabs li {
  border-radius: 10px;
}

.main__tab img,
.main__tab .lampIcon svg {
  width: 12px;
  height: 12px;
  stroke: var(--main-color);
}

.main__tab {
  border-radius: 10px;
  position: relative;
  display: block;
  /*overflow: hidden;*/
  /*margin-bottom: 5px;*/
  border-radius: 10px;
  /*box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);*/
}
.account .main__tab{
  box-shadow: none;
}
.main__tab.persons,.filters__btn.main__tab {
  background-color: white;
}
.filters__btn.main__tab{
  width: 60px;
  height: 60px;
}
.main__tab.compass-box {
  box-shadow: unset;
}

.right-side__toolbox,
.right-side__search,
.right-side__filters {
  margin-bottom: 0px !important;
  margin-right: max(calc(100vw / 384), 5px) !important;
}

.main__tab img,
.main__tab svg {
  width: 26px;
  height: 26px;
  stroke: var(--main-color);
}

.left-side__flag .main__tab svg * {
  stroke: var(--main-color);
}

.main__tab-btn {
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  z-index: 2;
  position: relative;
  background-color: #fff;
  margin: auto;
  /*box-shadow: 0px 0px 10px 0px #00000021;*/
}

@media (max-width: 1600px) {
  .top-btns .main__tab-btn, .header__burger {
      width: 48px;
      height: 48px;
  }

  .top-btns .main__tab-btn > * , .header__burger > * {
    transform: scale(0.8) translateZ(0);
    will-change: transform;
  }
}
.main__tab-btn__title{
  width: 83px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  overflow: hidden;
  align-items: flex-end;
  word-wrap: break-word;
}
.main__tab.persons.active .main__tab-btn:after {
  content: "";
  width: 100px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 10px;
  background-color: inherit;
}

.main__tab.active {
  overflow: unset;
}
.right-side__toolbox.main__tab.active button,
.categories-collapse.main__tab.active button,
.left-side__bottom .filters.active  button{
  background-color: var(--main-color);
}
.main__tab.active .main__tab-btn svg, .main__tab.active>svg {
  stroke: #fff;
}
.main__tab.active .main__tab-btn__title{
  color: var(--dynamic-screenshot-color)!important;
}

.account .main__tab.active .main__tab-btn svg,
.account .main__tab.active > svg,
.account .main__tab.active.dynamic-screenshot__main-tab .main__tab-btn svg *,
.main__tab.bookmarks__main-tab.active svg *{
  stroke: var(--dynamic-screenshot-color);
  fill:var(--dynamic-screenshot-color);
}

.activeTitle {
  color:var(--dynamic-screenshot-color);
}

.main__tab.active .main__subitem {
  width: 450px;
  height: 243px;
  padding-bottom: 14px;
}

.main__tab.active .main__subitem-title::before {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: var(--theme-color);
}

.main__subitem {
  position: absolute;
  top: 0;
  left: 68px;
  width: 0;
  height: 0;
  border-radius: 0 12px 12px 12px;
  background-color: #fff;
  /*overflow: hidden;*/
  z-index: 2;
}

.main__tab.false .main__subitem {
  display: none;
}

.main__subitem-heading {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  height: 60px;
  /* text-transform: uppercase; */
  color: #fff;
  letter-spacing: 2px;
  padding-left: 20px;
  /*contain: content;*/
  border-radius: 0px 12px 0px 0px;
}

.main__subitem-heading > span {
  background-color: var(--data-color);
  border-radius: 0px 12px 0px 0px;
}

.main__subitem-title {
  position: relative;
  z-index: 1;
  font-size: 16px;
  text-transform: uppercase;
}

.main__subitem-libtn {
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-align: left;
  width: 100%;
  align-items: center;
  display: inline;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1.1;
  padding: 10px 15px 10px 0;
  font-family: 'GheaGrapalat';
}

.main__subitem-btn {
  width: 100%;
  font-size: 18px;
  letter-spacing: 1.5px;
  /*text-transform: uppercase;*/
  font-weight: 500;
  text-align: left;
  line-height: 1.1;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 0;
}

.main__subitem-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-item.active .main__subitem-label {
  margin-right: 30px;
  left: unset !important;
  right: 0;
}

.dashboard-item.active .dashboard-main-btn {
  margin-right: 30px;
}

.category-filters {
  /* left:calc(100vw/6) */
  position: absolute;
  width: 24px;
  height: 24px;
  right: 2px;
}

.lesson__content-cat .main__subitem-label {
  cursor: pointer;
  display: flex;
  margin-left: auto;
  position: relative;
  z-index: 1;
  right: unset;
}

.main__subitem-heading .main__subitem-label {
  /*margin-right: 7px;*/
}

.main__subitem-label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.main__subitem-itemswrapper {
  display: flex;
  align-items: stretch;
  height: calc(100% - 47px);
  /*padding: calc(100dvh / 60.34) calc(100dvh / 57.53);*/
  height: inherit;
  overflow-y: auto;
  margin-right: 2px;
  padding-top: 0pc;
  padding-bottom: 0pc;
  padding-left: 0;
}
.empty-items{
  text-align: center;
  padding: 5px 3px;
}
.main__subitem-items {
  /*position: relative;*/
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.main__subitem-initem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 6px;
}

.main__subitem-initem .main__subitem-label {
  margin-right: 10px;
}

.main__subitem-item {
  width: calc(100% - 8px);
}

@media screen and (min-width: 900px) and (max-width: 1800px) {
  .main__subitem-item {
    width: calc(100% - 12px);
  }
}

.main__subitem-item.active .main__subitem-list {
  width: 100%;
  height: 100%;
}

ul.main__subitem-list.person {
  width: 100%;
  height: 100%;
}

ul.main__subitem-list.person li div:hover {
  cursor: pointer;
}

.main__subitem-item.active .main__subitem-arrow {
  transform: rotateX(180deg);
}

.main__subitem-list {
  overflow: hidden auto;
  width: 0;
  height: 0;
  padding: 5px 0;
}
.main__subitem-li {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  /* padding-right: 24px; */
  justify-content: space-between;
}

.main__subitem-li:not(:last-child) {
  margin-bottom: 5px;
}

.main__subitem-li > div {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 20px;
  transition: 0.2s;
  max-width: 72%;
  padding-left: 10px;
  margin-left: 10px;
}

.main__subitem-li > div:hover {
  transition: 0.2s;
  background-color: var(--data-color);
  color: var(--data-font-color)
  /*width: max-content;*/
}

.main__tab.legend .main__subitem-li > div:hover {
  background-color: inherit;
}

.main__tab.legend ul.main__subitem-list.person li div:hover, .main__left.left-side .main__tab.legend .Collapsible {
  cursor: default;
}
.main__left.left-side .main__tab.legend .Collapsible{
  cursor: default;
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr 74px;
  grid-template-areas:
        "t a"
        "c c";
  align-items: center;
  position: relative;
}
.main__left.left-side .main__tab.legend .Collapsible>:nth-child(1){
  grid-area: t;
}
.main__left.left-side .main__tab.legend .Collapsible>:nth-child(2){
  grid-area: a;
  right: 0;
  width: 100%;
  position: relative;
  height: 100%;
}
.main__left.left-side .main__tab.legend .Collapsible>:nth-child(3){
  grid-area: c;
}
/*.main__left.left-side .main__tab.legend .Collapsible:has(.is-open)>:nth-child(3){*/
/*  height: max-content!important;*/
/*}*/

.main__left.left-side .main__tab.legend  .Collapsible__trigger {
  cursor: pointer;
}

.main__subitem-li.active {
  background-color: var(--theme-color);
}

.main__subitem-close {
  display: block;
  width: 34px;
  height: 34px;
  opacity: 0.7;
}

.main__subitem-close > span {
  display: block;
  transform: rotate(45deg);
  margin-right: 10px;
}

.main__subitem-sort {
  display: block;
  width: 34px;
  height: 34px;
  z-index: 2;
}

.main__subitem-sort svg {
  width: 15px;
}

.sort-board[data-active="true"] {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 90%;
  top: 0;
  min-width: 227px;
  min-height: 144px;
  /* aspect-ratio: 1/1; */
  background-color: #fff;
  transform: translateY(50px);
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #00000012;
  text-align: left;
  justify-content: space-around;
  z-index: 2;
  padding: 10px;
}

.sort-board[data-active="false"] {
  display: none;
}

.sort-board:before {
  content: "";
  width: 100%;
  height: 11px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transform: translateY(-10px);
}

.sort-board:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #fff;
  transform: translateX(15px) translateY(-5px) rotate(45deg);
}

.sort-board > span {
  opacity: 1;
  color: rgba(44, 71, 108, 0.5);
  font-size: 15px;
  font-weight: 400;
  /*font-style: normal;*/
  letter-spacing: 2px;
  text-align: left;
  /* text-transform: uppercase; */
  margin-left: 23px;
}

.sort-board > div {
  display: flex;
  opacity: 1;
  color: rgba(44, 71, 108, 1);
  font-family: "Lato-Regular";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  align-items: center;
  gap: 10px;
  position: relative;
}

.sort-board input {
  visibility: hidden;
}

.sort-board label {
  cursor: pointer;
  white-space: nowrap;
}

.sort-board label:after {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 11px;
  height: 11px;
}

.sort-board input[type="radio"]:checked + label {
  font-weight: 700;
}

.sort-board input[type="radio"]:checked + label:after {
  background-image: url("../../assets/imgs/checked-icon.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.left-side {
  z-index: 1000;
  /*padding-left: 10px;*/
  /*margin-top: 46px;*/
  position: relative;
  width: max-content;
  margin-left: max(calc(100vw / 384), 5px);
  gap: calc(100vh / 369.94);
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.left-side__bottom {
  position: relative;
  /* height: 222px; */
  height: fit-content;
  gap: calc(100vh / 369.94);
  display: flex;
  flex-direction: column;
}

.left-side__hints {
  border-radius: 10px;
}

.right-side {
  position: absolute;
  right: 0;
  bottom: 30vh;
  width: calc(60px * 0.7);
  height: max-content;
  margin-right: calc(100vw / 426.66);
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.main__right.right-side .zoom-btns {
  width: 100%;
}

.main__right.right-side .main__tab.compass-box {
  width: 100%;
  height: calc(60px * 0.7);
  margin-top: 10px;
}

.main__right.right-side
  .main__tab.compass-box
  .map-controls-view__control-group {
  width: 100%;
  height: calc(60px * 0.7);
}

.main__right.right-side .return-nav-btn-box {
  width: 100%;
  height: calc(60px * 0.7);
  margin-top: 10px;
}

.main__right.right-side .zoom-btns .main__tab {
  width: 100%;
  height: calc(60px * 0.7);
}

.right-side__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.right-side__search.active {
  background-color: #0000 !important;
}
.right-side__search.main__tab.active .right-side__search-btn.main__tab-btn{
  height: 50px!important;
}
.right-side__search .search-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
}

.right-side__search-field {
  background-color: white;
  z-index: 99;
  min-height: 60px;
  position: relative;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0px);
  padding: 0px 10px;
  box-shadow: 0px 7px 26px rgba(44,71,108, 0.1);
  opacity: 1;
  border-radius: 11px;
}
.right-side__search-field > div{
  position: relative;
}
.right-side__search-content:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0px);
  height: 2px;
  width: 94%;
  border-bottom: 2px solid #2c476c;
  opacity: 0.5;
  z-index: 100;
}

.right-side__search-icon {
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.right-side__filter-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60%;
  right: 60px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.right-side__filter-icon {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.right-side__filter-box svg {
  z-index: 99;
}

.right-side__search-input {
  display: block;
  height: 50px;
  width: 100%;
}

.right-side__search-input {
  padding: 0 90px 0 0;
}

.right-side__search-input::placeholder {
  opacity: 0.4;
  color: rgba(44, 71, 108, 1);
  font-family: 'Noto Sans Armenian', sans-serif;
  font-size: max(18px, calc(100vh / 81.89));
  font-weight: 400;
  font-style: normal;
  letter-spacing: min(2px, calc(100vh / 818.9));
  text-align: left;
}

.right-side__search-content {
  position: absolute;
  top: -5px;
  right: 0;
  width: 0;
  height: 0;
  background-color: #fff;
  border-radius: max(10px, calc(100vh / 108.1));
  overflow: hidden;
}

.right-side__search-btn svg {
  stroke: var(--main-color) !important;
}

.right-side__search-filters {
  z-index: 98;
  border-radius: 10px;
  padding: calc(100vh / 51.47) calc(100vw / 41);
  display: none !important;
  position: absolute;
  left: calc(calc(-1 * 100vw / 6.98) * 18.93 / 100);
  top: 53px;
  width: max(200px, calc(100vw / 6.98));
  background-color: white;
  aspect-ratio: 1/1.04;
  overflow: unset !important;
}

.right-side__search-filters[data-show="false"] {
  display: flex !important;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-evenly;
}

.right-side__search-filters:after {
  content: "";
  position: absolute;
  top: -6px;
  transform: rotate(45deg);
  left: 17.63%;
  width: 30px;
  height: 30px;
  background-color: white;
  z-index: -1;
}

.right-side__search-filters:before {
  content: "";
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 63px;
  z-index: 9997;
}

.right-side__search.active .right-side__search-content {
  /* height: auto; */
  width: max(100%, calc(100vw / 3.89));
  aspect-ratio: 7.6/1;
  min-height: 60px;
  overflow: initial;
  z-index: 0;
}
.right-side__search.active .main__subitem{
  width: 100%;
}
.right-side__search .infinite-scroll-component, div#scrollableDiv  {
  position: relative;
  width: 100%;
  left: unset;
  right: 0;
  top: unset!important;
  bottom: 0;
  height: 275px;
  border-top-right-radius: 0;
  background-color: #fff;
  padding-bottom: 0;
}
div#scrollableDiv  {
  padding: 0;
  margin: 0;
}

.right-side__search .infinite-scroll-component ::before,
.right-side__search .infinite-scroll-component ::after {
  content: none;
}

.right-side__search.active .main__tab-btn {
  z-index: 99;
  background-color: #0000;
}

.right-side__search.active .right-side__search-response {
  position: absolute;
  height: 275px;
  width: max(100%, calc(100vw / 3.89));
  top: 51px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0px 0px 11px 11px;
  background-color: #fff;
  z-index: 98;
  padding: 10px 10px;
}

.right-side__filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
}

.right-side__search-content .with-svg {
  width: 100%;
  height: 100%;
}

.right-side__filters-btn {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100%;
  margin-right: 0!important;
  font-weight: 400;
  color: rgba(44, 71, 108, 1);
}
.right-side__filters-icon-wrapper {
  margin-right: 5px;
  height: 100%;
  min-height: 100%;
  scale: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-side__filters-icon-wrapper > .sprite-icon {
  width: 30px;
  height: 30px;
}

.right-side__filters-btn img,
.right-side__filters-btn svg {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.right-side__filters-title {
  font-weight: 400;
}

.right-side__filters-item {
  overflow: unset !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  overflow: hidden;
  padding: 4px 0px;
  color: rgba(44, 71, 108, 0.5);
  font-family: 'Noto Sans Armenian', sans-serif;
  font-size: max(18px, calc(100vh / 112.94));
  font-style: normal;
  letter-spacing: 1.3px;
  text-align: left;
  text-transform: uppercase;
}

img.activeState {
  position: absolute;
  left: -15px;
  width: 10px;
  height: 10px;
}

/*li.right-side__filters-item.categories.active:before {*/
/*    content: "";*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    position: absolute;*/
/*    right: 80px;*/
/*    overflow: unset;*/
/*    z-index: 3;*/
/*    background-repeat: no-repeat;*/
/*    background-size: calc(100vh/28.1);*/
/*    background-position: center calc(50vh/100 * -1);*/
/*  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='69px' height='70px' viewBox='0 0 69 70' version='1.1'%3E%3Ctitle%3EPath 3%3C/title%3E%3Cdefs%3E%3Cfilter x='-11.5%25' y='-10.6%25' width='123.1%25' height='121.3%25' filterUnits='objectBoundingBox' id='filter-1'%3E%3CfeOffset dx='0' dy='7' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur stdDeviation='13' in='shadowOffsetOuter1' result='shadowBlurOuter1'/%3E%3CfeColorMatrix values='0 0 0 0 0.17254902 0 0 0 0 0.278431373 0 0 0 0 0.423529412 0 0 0 0.1 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'%3E%3Cg id='Seach-Filter' transform='translate(-3287.000000, -277.000000)' stroke='%2366CAB2' stroke-width='4'%3E%3Cg id='Group-29' filter='url(%23filter-1)' transform='translate(3283.000000, 126.000000)'%3E%3Cpolyline id='Path-3' points='30 179.181818 37.6363636 189 47.8181818 169'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");*/
/*}*/
.right-side__filters-item.actv {
  background-color: #e9ecef;
}

.right-side__filters-item:not(:last-child) {
  /*margin-right: 13px;*/
}

.header__right .filters.active .main__tab-btn {
  background-color: var(--main-color);
}

.header__right .filters__content {
  left: unset;
  right: 0;
  top: 60px;
  border-radius: 12px;
}

/*.right-side__filters .filters__content:after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: -20px;*/
/*  width: 62px;*/
/*  height: 30px;*/
/*}*/

.left-side__bottom .filters {
  overflow: hidden;
}

.right-side__filters .filters {
  border-radius: 10px;
  overflow: hidden;
}

.filters__btn {
  position: relative;
}

.left-side__bottom .filters__content {
  position: absolute;
  top: 0;
  left: 65px;
  height: 0;
  width: 0;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  margin-left: calc(100vw / 384);
}

.right-side__filters .filters__content {
  position: absolute;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
}

.right-side__filters .filters.active .left-side__tab {
  background-color: #218cff;
}

.left-side__bottom .filters.active .filters__content {
  width: 260px;
  height: auto;
  overflow: unset;
  padding: 20px 15px 26px 15px;
}

.right-side__filters.filters.active .filters__content {
  max-width: 500px;
  height: auto;
  padding: 15px 25px;
  overflow: hidden;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-side__filters.filters.active .filters__content > .filters__radio {
  overflow-y: hidden;
  overflow:auto;
  white-space: nowrap;
}

.right-side__filters.filters.active .filters__content > .filters__radio::-webkit-resizer {
  display: none;
}

/*.base-map-and-switcher-container {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*}*/

.right-side__filters .filters__title {
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 12px;
}

.left-side__bottom .filters__title {
  opacity: 1;
  color: rgba(44, 71, 108, 1);
  font-family: "NotoSansArmenian-Bold";
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.64px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.left-side__bottom .filters__radio {
  border: none;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.right-side__filters .filters__radio {
  border: none;
  display: flex;
  gap: 12px;
}

.left-side__bottom .filters__radio-label {
  position: relative;
  display: block;
  text-align: center;
  border: 1px solid var(--main-color);
  border-radius: 15px;
  overflow: hidden;
}

.right-side__filters .filters__radio-label {
  cursor: pointer;
  position: relative;
  text-align: center;
  /*border: 1px solid var(--main-color);*/
  /*border-radius: 15px;*/
  overflow: hidden;
}

.mode-switch-box {
  height: 100%;
  width: 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 8px;
}

.mode-switch-box > .filters__radio-label {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters__radio-label__img-box {
  margin: auto;
  height: 55px;
  aspect-ratio: 1/1;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters__radio-label__img-box.mode {
  height: calc(100vh / 40.79);
  aspect-ratio: 1/1;
  /* background-color: red; */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.filters__radio-label {
  cursor: pointer;
}

.filters__radio-label__img-box.light-mode-icon {
  background-image: url("../../assets/imgs/icons/basemap-day.svg");
}

.filters__radio-label
  input:checked
  + .filters__radio-label__img-box.light-mode-icon {
  border: 0 !important;
  background-image: url("../../assets/imgs/icons/basemap-day-selected.svg");
}

.filters__radio-label__img-box.dark-mode-icon {
  background-image: url("../../assets/imgs/icons/basemap-night.svg");
}

.filters__radio-label
  input:checked
  + .filters__radio-label__img-box.dark-mode-icon {
  border: 0 !important;
  background-image: url("../../assets/imgs/icons/basemap-night-selected.svg");
}

.filters__radio-label__img {
  width: 85% !important;
  height: 85% !important;
  border-radius: 10px;
}

.filters__radio-label:not(:last-child) {
  /*margin-bottom: 10px;*/
}

.filters__radio-label input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.right-side__filters
  .filters__radio-label
  input:checked
  + .filters__radio-label__img-box {
  border: 2px solid rgba(5, 118, 244, 1);
}

.left-side__bottom .filters__radio-label input:checked + .filters__radio-text {
  background-color: var(--main-color);
  color: #fff;
}

.bg {
  background-color: var(--main-color);
  color: #fff;
}

.left-side__bottom .filters__radio-text {
  display: block;
  width: 100%;
  height: 100%;
  padding: 3px;
  text-transform: uppercase;
}

.right-side__filters .filters__radio-text {
  display: block;
  padding: 3px;
  color: var(--main-color);
  opacity: 1;
  font-family: "Noto Sans Armenian";
  font-size: 12px;
  font-weight: 500;
  /*letter-spacing: 1.21px;*/
  text-align: center;
  max-width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.response {
  padding: 10px 10px 0 20px;
}

.response__items::after {
  content: "";
  display: block;
  width: 100%;
  height: 20px;
}

.response__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

.response__item-icon {
  margin-right: 5px;
}

.articles-grid {
  margin-top: 10px;
  right: 0;
  width: auto;
  /* max-width: 450px; */
  overflow: visible;
  max-height: 400px;
  height: 100%;
  display: grid;
  /*grid-template-rows: repeat(5, 80px);*/
  grid-auto-flow: column;
  grid-auto-columns: auto;
  column-gap: 15px;
  z-index: 99;
}

.hero {
  /*position: absolute;*/
  right: 4px;
  width: 100%;
  max-width: 450px;
  max-height: 356px;
  height: 100%;
  z-index: 12;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 30px;
}

.hero.minimized {
  position: relative;
  max-height: 63px;
  max-width: 350px;
  min-width: 295px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  margin-bottom: 20px;
  z-index: 1;
}

.hero.minimized .hero__media {
  pointer-events: none;
  float: none;
  margin: 0;
  margin: 0 auto;
}

.hero.minimized .hero__more,
.hero.minimized .hero__audio,
.hero.minimized .hero__category-btns,
.hero.minimized .hero__category,
.hero.minimized .hero__more,
.hero.minimized .hero__text,
.hero.minimized .hero__bottom {
  display: none;
}

.hero.minimized .hero__media {
  width: 35px;
  height: 35px;
}

.hero.minimized .hero__info {
  /*display: flex;*/
  width: 79%;
}

.hero.minimized .hero__header {
  width: 100%;
  margin-bottom: 0;
}

.hero__media img {
  /*width: fit-content;*/
  height: 100%;
  display: block;
  object-fit: cover;
  width: 100%;
}

.hero.minimized .hero__collapse {
  margin-right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.hero__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-area: h;
  order: 2;
  gap: 10px;
}

.hero__text {
  padding-right: 20px;
}

.articles-grid .padding-right-hero {
  padding-right: 2px;
}

.hero__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.hero__btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
}

.hero__collapse {
  margin: 0 5px 0 10px;
  width: 25px;
  height: 25px;
}

.hero__font {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.hero__close {
  width: 25px;
  height: 25px;
  margin-left: 4px;
  margin-right: 4px;
}

.hero__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  opacity: 1;
  font-size: 17px;
  letter-spacing: 0.75px;
  color: rgba(44, 71, 108, 1);
  font-weight: 700;
  font-style: normal;
  text-align: left;
  text-transform: uppercase;
  min-width: 100px;
  line-height: 20px;
}

.hero__descr {
  /*line-height: 1.7;*/
  max-height: 250px;
  overflow-y: auto;
  /*font-size: 12px;*/
  opacity: 1;
  color: rgba(16, 26, 41, 1);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 25px;
}

.hero__descr div {
  width: 175px;
  word-wrap: break-word
}

.hero__media-wrapper {
  grid-area: m;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  justify-content: space-around;
  gap: 10px;
}

.popup-image-section {
  justify-content: flex-start;
}

.hero__media {
  position: relative;
  width: 135px;
  height: 135px;
  /*margin-bottom: 20px;*/
  border-radius: 50%;
  overflow: hidden;
}
.hero__media_not_found .hero__media{
  cursor: not-allowed;
}
.hero__media-gallery {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero__info {
  grid-area: i;
  font-size: 11px;
  width: 100%;
}

.hero__info-wrapper {
  position: relative;
  background-color: #fff;
  display: grid;
  align-items: stretch;
  padding: 15px 20px 25px;
  grid-template-areas: "h h" "m i";
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 40px auto;
  border-radius: 16px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.minimized .hero__info-wrapper {
  padding: 5px 20px;
  grid-template-areas: "m h h";
  align-items: center;
}

.hero__media_icons {
  display: flex;
  gap: 10px;
}

.hero.minimized .hero__media_icons,
.hero.minimized .hero__media-items {
  display: none;
}

.hero__more {
  background-color: #ffde5a;
  display: block;
  width: 100%;
  max-width: 135px;
  height: 28px;
  border-radius: 14px;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1.2px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s;
}

.hero__more:hover {
  background-color: #ffcc01;
}
.hero__more:disabled {
  background-color: rgba(255, 204, 1, 0.49);
  color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}

.hero__audio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 50px;
  /*background-color: rgba(44, 71, 108, .9);*/
  background-color: #eaedf0;
  font-size: 12px;
  /*color: #fff;*/
  color: #2c476c;
  position: absolute;
  bottom: 0;
  border-radius: 10px;
}

.hero__audio-play {
  flex-shrink: 0;
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  /*margin-left: 20px;*/
  border-radius: 50%;
  /*border: 1px solid rgba(255, 255, 255, .3);*/
  border: 1px solid #b4bdca;
}

.hero__audio-play svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  /*fill: #fff;*/
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  fill: RGB(44, 71, 108);
}

.hero__audio-play .audio-pause {
  visibility: visible;
  opacity: 1;
}

.hero__audio-play .audio-pause.paused {
  opacity: 0;
  visibility: hidden;
}

.hero__audio-play .audio-play {
  opacity: 1;
  visibility: visible;
}

.hero__audio-play .audio-play.paused {
  visibility: hidden;
  opacity: 0;
}

.hero__audio-currtime {
  font-family: "Avenir Next";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px;
}

.hero__audio-track {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-appearance: none;
  /*background-color: rgba(255, 255, 255, .3);*/
  border-radius: 0;
  height: 2px;
}

.hero__audio-track::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  opacity: 0;
}

.hero__audio:hover .hero__audio-track::-webkit-slider-thumb {
  opacity: 1;
}

.hero__audio-duration {
  font-family: "Avenir Next";
  display: block;
  margin: 0 20px;
}

.hero__audio-volume {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

/*.hero__audio-volume:hover {*/
/*    width: 60px;*/
/*}*/
/*.hero__audio-volume:hover .volume-track {*/
/*    z-index: 1;*/
/*}*/

.hero__audio-volume .volume-track {
  position: absolute;
  height: 60px;
  width: 100%;
  /*background: #38567a;*/
  /*bottom: 25px;*/
  /*left: -5px;*/
  bottom: -5px;
  left: 5px;
  padding: 10px;
}

.css-b9pdga-MuiSlider-root {
  padding: 0 !important;
}

.hero__audio-volume .volume-track input {
  height: 2px;
  width: 100%;
  -webkit-appearance: none;
  background-color: #fff;
}

.hero__audio-volume .volume-track input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.hero__audio-volume .volume-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20px;
  height: 20px;
  z-index: 1;
  /*margin-right: 8px;*/
}

.hero__audio-volume .volume-btn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}

.hero__audio-volume .volume-btn.muted {
  position: relative;
}

.hero__audio-volume .volume-btn.muted svg {
  opacity: 0.4;
}

.hero__audio-volume .volume-btn.muted::before {
  content: "";
  width: 35px;
  height: 1px;
  background-color: #ff0000;
  position: absolute;
  left: 47%;
  top: 47%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.hero__category {
  margin-bottom: 10px;
}

.hero__category-pages {
  opacity: 0.6;
}

.hero__category-btns {
  font-family: "Lucida Grande";
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  margin-bottom: 5px;
  height: 15px;
}

.hero__category-btns button {
  display: block;
  width: 15px;
  height: 15px;
  font-size: 12px;
}

.hero__category-prev svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.hero__category-btns button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.hints {
  position: relative;
  margin-top: 0px;
  overflow: hidden;
}

.hints_lamp {
  position: relative;
  margin-top: -5px;
  overflow: hidden;
  z-index: 3;
}

.hints.hints.active {
  overflow: unset;
}

.hints__content {
  position: absolute;
  left: 61px;
}

.hints__content-item {
  display: flex;
  align-items: flex-start;
  /*margin-bottom: 15px;*/
  background-color: red;
}
.hints__content-parent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.hints__content-box{
  position: relative;
  width: 57px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  contain: content;
  /*margin-right: 15px;*/

}
.hints__content-img {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.hints__content-box .hints__content-img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
   margin-right: 0;
  flex-shrink: 0;
  z-index: 2;
  position: relative;
  border: 3px solid white;
}
.hints__content-img img {
  height: 100%;
}
.hints__content-box:hover .hero__media__liner-gradien{
  animation: rotation 2.5s infinite ease;
}
.hints__content-box:hover .hints__content-img img{
  opacity: 80%;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.hints__content-name {
  text-align: left;
  font-size: 13px;
  /*margin-bottom: 10px;*/
  color: #2c476c;
  /*max-height: 58px;*/
}
.hints__content-name  span{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 17.6px;
}
.hints__content-text {
  /*max-width: 150px;*/
}

.hints__content-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 0;
}

.hints__content-bottom span {
  font-weight: 500;
  color: #2c476c;
  font-size: 13px;
  text-wrap: nowrap;
  display: flex;
  flex-flow: nowrap;
  white-space: nowrap;
}

.hints__content-bottom svg {
  margin: 0 19px;
}

.hints__close {
  position: absolute;
  right: 12px;
  top: 9px;
}

.hints.active .hints__content {
  width: 407px;
  height: 243px;
}

.splide__slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.hints__title {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 12px;
}

.hints__radio {
  border: none;
}

.hints__radio-label {
  position: relative;
  display: block;
  text-align: center;
  border: 1px solid #888888;
  border-radius: 8px;
  overflow: hidden;
}

.hints__radio-label:not(:last-child) {
  margin-bottom: 8px;
}

.hints__radio-label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.hints__radio-label input:checked + .filters__radio-text {
  background-color: #218cff;
}

.hints__radio-text {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: rgba(32, 33, 36, 0.6);
}

.hints .splide__arrows {
  position: absolute;
  bottom: 4px;
  display: flex;
  align-items: center;
  width: 34px;
  height: 13px;
  left: 86%;
  /* transform: translate(-50%, -50%); */
  top: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.hints .splide__arrows svg {
  display: none;
}

.hints .splide__arrows button {
  width: 13px;
  height: 13px;
  background-color: transparent;
  background: url("../../assets/imgs/arrow_lamp_moda.svg") no-repeat
    center/contain;
  opacity: 1;
}

.hints .splide__arrows button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.hints .splide__arrow--prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.hints .splide__arrow--next {
  right: 0;
}

.zoom-btns .main__tab:first-child {
  margin-bottom: 10px;
}

.zoom-btn,
.return-nav-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.zoom-btn{
  background-color: #fff;
  border-radius: 10px;
}
.return-nav-btn-box {
  margin-top: calc(100vh / 83);
  border-radius: calc(100vh / 108);
  background-color: rgba(255, 255, 255, 1);
}

.lesson {
  position: relative;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
  height: 44px;
}

.lesson-back {
  position: absolute;
  z-index: 2;
  left: 0;
  top: -61px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  white-space: nowrap;
  background-color: #fff;
  transform: rotate(180deg);
}

.lesson__names {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: -ms-grid;
  display: grid;
  z-index: 1;
}

.lesson__name {
  width: 100%;
  background-color: var(--main-color);
  font-size: 14px;
  padding: 8px 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 2;
  color: white;
  text-align: center;
  text-transform: uppercase;
}

.lesson__prev {
  text-align: right;
}

.lesson__next {
  text-align: left;
}

.lesson__current {
  text-align: center;
}

.lesson__content-catwrap {
  -ms-grid-columns: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 100%;
  margin-top: 35px;
  max-height: 145px;
  overflow-x: hidden;
  overflow-y: auto;
}

.lesson__content-cat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.lesson__content-cat > .main__subitem-label {
  flex-shrink: 0;
  padding: 0 10px;
  display: flex;
  place-items: center;
}

.lesson__content-cat > .main__subitem-label .eye::before {
  margin-right: unset;
}

.lesson__content-cat > .main__subitem-label .eye::after {
  left: 0;
}

/*.lesson__content-cat:not(:last-child) .lesson__content-items {*/
/*    border-bottom: 1px solid #888888;*/
/*}*/

.lesson__content-items {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-x: auto;
  padding-right: 10px;
}

.lesson__content-svg {
  flex-shrink: 0;
  width: 60px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5px;
}

.lesson__content-item {
  padding: 5px 15px;
  color: #ffffff;
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.lesson__content-name img {
  filter: brightness(10);
}

.main__tab.active .main__tab-btn .main__tab-icon img {
  filter: brightness(10);
}
span.main__tab-icon.icon-wrapper.screenshot-icon img {
  object-fit: contain;
}
.main__tab.active .main__tab-btn .main__tab-icon.search img {
  filter: none;
  /* margin-left: -4px; */
  cursor: pointer;
  z-index: 9999;
}

.lesson__content-item .main__subitem-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--theme-color);
  padding: 5px 10px;
  border-radius: 3px;
  margin-left: 10px;
}

.lesson__content-name {
  display: flex;
  align-items: center;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 5px;
}

.lesson__content-name svg {
  margin-right: 5px;
  margin-top: -3px;
}

.lesson__content-item .main__subitem-label .eye {
  border-right: 1px solid #464646;
}

.lesson__show {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 33, 36, 0.6);
}

.lesson__show-inner {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.lesson__show-text {
  margin-top: 44px;
  margin-bottom: 24px;
  font-weight: 500;
}

.lesson__show-label {
  position: relative;
}

.lesson__show-label .eye::before {
  width: 40px;
  height: 30px;
}

.lesson__show-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.lg-icon {
  display: block;
  width: 48px;
  height: 48px;
  background-color: transparent;
  background: url("../../assets/imgs/arrow.svg") no-repeat center/contain;
}

.lg-icon::before,
.lg-icon::after {
  content: none;
}

.element.style {
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-backdrop.in {
  opacity: 0.7 !important;
}

.lg-outer {
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lg-content {
  max-width: 1292px;
  left: 50% !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.lg-outer .lg-thumb-outer {
  background-color: transparent;
  float: unset;
  margin: 0 auto;
  width: 92.5%;
}

.lg-prev {
  left: -50px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.lg-next {
  right: -50px;
}

.lg-download {
  display: none !important;
}

.lg-counter {
  opacity: 0;
  visibility: hidden;
}

.lg-toolbar .lg-icon {
  color: #fff;
}

.mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
  left: 0;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 11px/20px "Noto Sans Armenian", "sans-serif";
  z-index: 1002;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

.mapboxgl-popup-content {
  border-radius: 200px !important;
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}

.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
  display: none;
}

textarea:focus,
input:focus {
  outline: none;
}

.hints__content_item {
  width: 100%;
  height: 60px;
  background-color: #ffb301;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quiz_content{
  background: #9E5D82;
}
.hints__content {
  display: grid;
  grid-template-rows: 60px max-content;
}

.hints__content_title {
  font-weight: 700;
  color: #ffffff;
  font-size: 16px;
  padding-left: 25px;
  text-transform: uppercase;
}

.hints__content_buttons {
  display: flex;
  align-items: center;
}

.lamp_header_icon {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 345px;
}

.hints__content_buttons_eye {
  width: 16px;
  height: 16px;
  margin-right: 12.5px;
  cursor: pointer;
}

.hints__content_itemswrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  overflow-y: scroll;
  height: calc(100% + 15px);
  padding: 15px 0;
  /* margin-top: 10px; */
  width: 100%;
  justify-content: space-between;
}

.hints__content_itemswrapper_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 6px;
}

.hints__content_itemswrapper_item_icon_and_text {
  display: flex;
  align-items: center;
}

.hints__content_itemswrapper_item_icon {
  display: flex;
  align-items: center;
}

.hints__subitem_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 6px;
}

.hints__subitem_item .main__subitem-label {
  /*margin-right: 10px;*/
}


.tic_animation {
  animation: beat 0.4s infinite alternate;
}
.tic_animation {
  width:var(--size);
  height:var(--size);
  overflow:hidden;
  margin:auto;
  z-index: 1;
  background-color: #0000;
  border: 8px solid ;
  border-color:var(--catColor);
  border-radius: 50%;
  animation: beat 1s infinite cubic-bezier(0.45, 0.44, 0.55, 0.55);
  opacity: 1!important;
}

/* Heart beat animation */
@keyframes beat {
  from {
    width:var(--size);
    height:var(--size);
  }
  to {
    width: calc(var(--size) - 5px);
    height: calc(var(--size) - 5px);
  }
}
.hints__content_itemswrapper_lamp {
  padding-right: 14px;
  overflow: hidden;
}
.hints__content_itemswrapper_lamp .main__subitem-items{
  display: grid;
    grid-template-columns: 100%;
}
.main__subitem_checkbox_lamp {
  padding-right: 10px;
}

.hints__subitem_item_lamp {
  padding-right: 10px;
}

.eye_lamp_icon {
  width: 22px !important;
  height: 22px !important;
}

.main__subitem-label_lamp {
  cursor: pointer !important;
}

.main__subitem-btn_lamp {
  padding: 0;
  overflow: hidden;
  display: inline-block;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
  width: 250px;
  line-height: unset;
}

.main__subitem-item_lamp {
  margin-bottom: 8px;
  position: relative;
}

.main__subitem-item_lamp .main__subitem-label_lamp {
   left:calc(85% + 25px)
}

.hints__subitem_item_lamp_content {
  width: 89%;
  display: flex;
  align-items: center;
  border-radius: 17px;
  padding: 4px 10px;
}

.hints__subitem_item_lamp_content:hover {
  background-color: #ffb207;
  color: #fff;
  cursor: pointer;
}
li.main__subitem-item_quiz .hints__subitem_item_quiz{
  border: 2.2px solid #9E5D82;
  border-radius: 34.1px;
  margin: auto;
  width: 90%;
  cursor: pointer;
  transition: .2s;
}
li.main__subitem-item_quiz .hints__subitem_item_quiz:hover {
  background-color: #9E5D82;
}
li.main__subitem-item_quiz .hints__subitem_item_quiz .main__subitem-btn_quiz{
  padding: 10px;
  font-family: 'Noto Sans Armenian';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  /* identical to box height */
  letter-spacing: 1.5px;
  color: #2C476C;
  /*text-transform: uppercase;*/
  transition: .2s;
}
.quiz__content .hints__content_itemswrapper_quiz {
  padding-right: 14px;
  overflow: hidden;
}
.hints__content_itemswrapper_quiz .main__subitem-items_quiz{
  display: grid;
  gap: 10px;
}
li.main__subitem-item_quiz .hints__subitem_item_quiz:hover .main__subitem-btn_quiz {
  color: #fff;
}
.sorted_tooltip {
  width: 227px;
  /* height: 207px; */
  background-color: #fff;
  position: absolute;
  left: 406px;
  top: 57px;
  z-index: 9999;
  box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
  border-radius: 10px;
  padding-bottom: 24px;
}

.sorted_tooltip div {
  display: flex;
  align-items: center;
  /* margin-bottom: 24px; */
}

.sorted_tooltip_content {
  margin-top: 20px;
}

.sorted_tooltip_content span {
  margin-left: calc(43px - 9.5px - 15px);
  font-weight: 400;
  font-size: 16px;
}

.sorted_tooltip_content_active_img {
  display: inline-block;
}

.sorted_tooltip_arrow {
  width: 18px;
  height: 18px;
  background-color: #fff;
  position: absolute;
  left: 18%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.sorted_tooltip_title {
  margin-top: 22px;
  color: rgba(44, 71, 108, 0.5) !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  letter-spacing: 2px;
  margin-left: 43px;
}

.sorted_tooltip_content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sorted_tooltip_content div {
  height: 10px;
  margin-left: 15px;
  visibility: hidden;
}

.sorted_tooltip_content_active span {
  font-weight: bold !important;
}

.sorted_tooltip_content_active div {
  visibility: visible;
}

.hints__btn_lamp,.hints__btn_quiz{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hints__btn_lamp img {
  width: auto;
  height: 24px;
}
.hints__btn_quiz img {
  width: auto;
  height: 24px;
}

.hide_lamp_article {
  visibility: hidden !important;
}

.show_lamp_article {
  visibility: visible !important;
}
.left-side__hints.hints.active .hints__btn_quiz:after{
  content: "";
  width: 100px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 10px;
  background-color: inherit;
}
.lampIcon img {
  width: 12px !important;
  height: 18px !important;
}
.pin-shortTitle-popup {
  top: 70px;
}
.splide.hints__content-items_lamp.splide--slide.splide--ltr.splide--draggable.is-active.is-initialized {
  width: 332px !important;
  background: #fff !important;
  height: 109px !important;
  padding: 14px 42.5px 14px 12.5px;
  border-radius: 10px;
  border: 2px solid #ffb207;
  margin-top: 5px;
}

.hints__content-item_lamp {
  background-color: transparent !important;
}

.modal_close_block {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}

.modal_close_block img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.hints_lamp .splide__arrows {
  position: absolute;
  bottom: 4px;
  display: flex;
  align-items: center;
  width: 60px;
  height: 13px;
  left: 86%;
  transform: translate(-50%, -50%);
  top: 75%;
}

.hints_lamp .splide__arrows svg {
  display: none;
}

.hints_lamp .splide__arrows button {
  width: 18px;
  height: 18px;
  background-color: transparent;
  background: url("../../assets/imgs/arrow_lamp_moda.svg") no-repeat
    center/contain;
  opacity: 1;
}

.hints_lamp .splide__arrows button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.hints_lamp .splide__arrow--prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.hints_lamp .splide__arrow--next {
  right: 0;
}

.counter_element {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  font-size: 13px;
  text-wrap: nowrap;
}

.first_counter {
}

.splide__arrows {
  z-index: 9999999;
}

.scroll_and_line_change {
  height: 100px;
  width: 52px;
  position: absolute;
  right: -53px;
  bottom: 0px;
  z-index: 999;
  display: flex;
  align-items: center;
  /* border: 2px solid black; */
  contain: content;
  border-radius: 10px 0px 0 0;
  box-shadow: -9px 13px 15px 0px #00000033;
}
.scroll_and_line_change_left_border:after{
  content: "";
  position: absolute;
  width: 1px;
  height: 111%;
  border-left: 1.33px solid #d6dbe3;
  z-index: 2;
  left: 0;
  top: 0;
}
.scroll_and_line_change_buttons {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.icon_maximize {
  width: 15px;
  height: 15px;
}

.icon_minimize {
  width: 15px;
  height: 2px;
  margin-top: 15px;
}
.scroll_and_line_change_buttons_minus{
  cursor: pointer;
  transition: 0.1s;
}
.scroll_and_line_change_buttons_minus:hover {
  scale: 1;
}
.scroll_and_line_change_buttons_minus:active {
  scale: 0.9;
}
.scroll_and_line_change_buttons_plus {
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.1s;
}

.scroll_and_line_change_buttons_plus:hover {
  scale: 1;
}
.scroll_and_line_change_buttons_plus:active {
  scale: 0.9;
}
.main__subitem-label {
  position: absolute;
  display: flex;
  z-index: 1;
  cursor: pointer;
  left: 385px;
  /*margin-right: 31px;*/
  /* left: calc(100vw/5.7); */
}
.main__subitem-label.inActive {
  cursor: default;
}
.clock-icon{
  left: 345px!important;
}
@media screen and (min-width: 900px) and (max-width: 3000px) {
  .left-side {
    zoom: 70%;
    -moz-transform: scale(0.7)
  }

  /*.articles-grid {*/
  /*  zoom: 70%;*/
  /*}*/

  .hero__info-wrapper{
    padding: 11px 14px 18px
  }
  .hero {
    max-width: 351px;
    max-height: 283px;
    padding-bottom: 21px;
  }
  .hero__descr {
    max-height: 175px;
    font-size: 12px;
    line-height: 17px;
  }
  .hero__title {
    font-size: 12px;
    letter-spacing: 0.52px;
    min-width: 70px;
    line-height: 14px;
  }
  .hero__collapse {
    margin-right: 3px;
    width: 17.5px;
    height:  17.5px;
  }
  .hero__font {
    margin-top: -5px;
    width: 16px;
    height:  16px;
  }
  .return_navigation_button svg {
    width: calc(24px * 0.7);
    height: calc(24px * 0.7);
  }
}

@media screen and (min-width: 1801px) {
  .category-filters {
    right: 3.5px !important;
  }

  .right-side {
    width: 60px !important;
    top: 50%;
    transform: translateY(-54%);
    zoom: 70%;
    -moz-transform: scale(0.7)
  }

  @-moz-document url-prefix() {
    .right-side {
      top: 28%;
    }
  }

  .main__right.right-side .zoom-btns .main__tab {
    width: 100%;
    height: 60px !important;
  }

  .main__right.right-side .return-nav-btn-box {
    height: 60px !important;
  }

  .return_navigation_button svg {
    width: 24 !important;
    height: 24 !important;
  }

  .main__right.right-side .main__tab.compass-box {
    height: 60px;
  }

  .main__right.right-side
    .main__tab.compass-box
    .map-controls-view__control-group {
    width: 100%;
    height: 60px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
  /*.account .main__tab-btn {*/
  /*  transform: scale(0.8);*/
  /*}*/
  .account .main__tab-btn__title{
    font-size: 10px;
  }
  .hero__descr {
    line-height: 22px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1025px) {
  .hero__descr {
    line-height: 22px;
  }
}
@media (max-width: 1024px){
  .main__right.right-side .main__tab.compass-box[data-is-loading="true"]{
    height: 36px;
    margin-bottom: 15px;
  }
}
@media (min-width: 1025px){
  .hero {
    position: absolute;
  }
  .category_menu_button {
    left: 0px;
  }
}
@media (max-width: 1024px) and  (max-height: 900px) and (orientation: landscape) {
  .main__subitem-itemswrapper{
    max-height: calc(100dvh - 300px) !important;
    /*height: calc(100vh - 315px) !important;*/
  }
  .legend .main__subitem{
    max-height: calc(100dvh - 250px) !important;
  }
}
@media screen and (max-width: 899px) {
  .right-side {
    width: 60px !important;
    top: 50%;
    transform: translateY(-54%);
  }

  .main__right.right-side .zoom-btns .main__tab {
    width: 100%;
    height: 60px !important;
  }

  .main__right.right-side .return-nav-btn-box {
    height: 60px !important;
  }

  .return_navigation_button svg {
    width: 24 !important;
    height: 24 !important;
  }

  .main__right.right-side .main__tab.compass-box {
    height: 60px;
  }

  .main__right.right-side
    .main__tab.compass-box
    .map-controls-view__control-group {
    width: 100%;
    height: 60px;
  }
}
.lampIcon{
  position: absolute;
  top: -10px;
  right: -10px;
  background: #FFB207;
  border-radius:40px;
  padding: 3px 9px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*update 16/11/23 */
@media (min-width: 512px) and (max-width: 1024px) {
  .hints__content {
    left: 40px;
  }
  .hints__content_item {
    min-height: 36px !important;
  }
  .hints.active .hints__content{
    width: 315px !important;
  }
  .lamp_header_icon {
    left: 275px;
  }
  .main__subitem-item_lamp .main__subitem-label {
    left: 276px;
  }
}
@media (max-width: 1024px) {
  .hero__more {
    font-size: 10px;
  }
  .hero__descr div {
    width: 180px;
    font-stretch: condensed;
  }
  .main__tabs-wrapper.active{
    gap: 5px;
  }
  li.main__subitem-item_quiz .hints__subitem_item_quiz .main__subitem-btn_quiz {
    font-size: 12px;
    line-height: 1.3;
  }
  .category_menu_button{
    left: 16px;
  }
  .left-side {
    zoom: unset!important;
    -moz-transform: scale(1) !important;
  }
  .main__subitem-title {
    font-size: 12px;
  }
  .main__subitem-libtn{
    padding: 5px 15px 5px 0;
  }
  .main__subitem-li > div{
    padding: 0;
  }
  ul.main__tabs.left-side__tabs{
    gap: 5px;
  }
  .left-side__bottom .filters__title{
    font-size: 14px;
  }
  .sorted_tooltip_title {
    margin-left: 32px;
  }
  .sorted_tooltip div{
    display: grid;
    grid-template-columns: 13px 1fr;
  }

}
@media (max-width: 418px) {
  .main__tab-btn__title {
    width: auto;
  }
}
@media (max-width: 512px) {
  .main__tab img,
  .main__tab svg {
    width: 16px;
    height: max-content;
    object-fit: contain;
    max-height: 26px;
  }
  .color_circle svg{
    aspect-ratio: 1/1;
  }
  label.main__subitem-label.clock-icon svg  {
    width: 26px;
  }
  .hints__content-bottom span{
    font-family: Noto Sans Armenian;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }

  .splide.hints__content-items_lamp.splide--slide.splide--ltr.splide--draggable.is-active.is-initialized {
    width: 282px !important;
  }
  .lampIcon{
    width: 25px!important;
    height: 25px!important;
  }
  .main__subitem-sort {
    width: 25px;
    height: 25px;
    display: none;
  }
  .hints__content_buttons_eye{
    display: none;
  }
  .main__subitem-sort svg {
    width: 12px;
  }
  .main__left.left-side .Collapsible__trigger{
    font-size: 14px;
  }
  .main__left.left-side .Collapsible__trigger .collapseIcon{
    left: 195px;
  }
  .main__left.left-side .Collapsible .main__subitem-label.item {
    left: 220.5px;
  }
  .main__left.left-side .Collapsible__contentOuter .main__subitem-label {
    left: 245px;
  }
  .main__subitem-title{
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    width: 165px;
  }
  .main__left.left-side .legend  .main__subitem-heading>.main__subitem-text-label{
    left: 224px;
  }
  .main__left.left-side .legend .main__subitem-heading>.main__subitem-label {
    left: 255px;
  }
  .main__left.left-side .Collapsible .legend-layar-text{
    left: 62.5%;

  }
  .main__left.left-side .Collapsible .legend-layar-eye{
    left: 59%;
  }
  .hints__content {
    left: 44px;
  }
  .left-side__bottom .filters.active .filters__content{
    width: 190px;
    height: auto;
    overflow: unset;
    padding: 15px 10px 21px 10px;
  }
  .left-side__bottom .filters__title{
    font-size: 14px;
  }
  .dashboard-item.active .dashboard-main-btn {
    margin-right: 40px!important;
  }
  .dashboard-main-btn{
    pointer-events: none;
  }
}
@-moz-document url-prefix() {
  .first__main_btn {
    margin-top: -105%;
  }
  .category_menu_button{
    left: -10px;
  }
}
