.microArticle-dashboards {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(216, 216, 216, 0.4);
    margin: 140px 30px 40px;
    padding: 30px 30px 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: #2c476c;
    border-radius: 11px;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 186px);
}

.account-dashboards--search {
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.account-dashboards--content {
    width: 100%;
    height: fit-content;
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(5, calc(20% - 9px));
    grid-template-rows: repeat(2, minmax(10px, 250px));
    justify-items: center; /* Aligns items horizontally */
    align-items: center;   /* Aligns items vertically */
    gap: 20px;
}

.microArticle-empty-data {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.microArticle-empty-data h2 {
    font-family: "sans-serif, Arial";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 19px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
}

.loader-container {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1222;
}

.account-dashboards--pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.account-dashboards--pagination .small {
    display: none;
}

.account-dashboards--pagination .medium {
    display: unset;
}

.microArticle-dashboards :global(ul.MuiPagination-ul li:first-child),
:global(ul.MuiPagination-ul li:last-child) {
    background-color: #f3f2f7;
    border-radius: 50%;
    margin: 0px 10px;
}

.microArticle-dashboards :global(ul.MuiPagination-ul li:nth-child(2)) {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}

.microArticle-dashboards :global(ul.MuiPagination-ul li:nth-last-child(-2n + 2)) {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.microArticle-dashboards :global(ul.MuiPagination-ul li:not(:first-child, :last-child)) {
    background-color: #d9d9d9;
    height: 30px;
}

.microArticle-dashboards :global(ul.MuiPagination-ul li) button {
    margin: 0px;
}

.microArticle-dashboards
:global(ul.MuiPagination-ul li:not(:first-child, :last-child))
button {
    top: -1px;
}

.microArticle-dashboards :global(ul.MuiPagination-ul li) button.Mui-selected {
    background-color: #3985EC !important;
    color: #fff !important;
}

.search__input {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    padding: 10px 12px;
    width: 22%;
    align-self: flex-end;
    margin: 0 15px 15px 0;
    color: #d8d8d8;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.search__input::placeholder {
    color: #d8d8d8;
}

.input:focus::placeholder {
    opacity: 0;
}

@media screen and (max-width: 900px) {
    .microArticle-empty-data h2 {
        margin: 0 7px;
        line-height: 28px;
        text-align: center;
        text-wrap: wrap;
    }

    .microArticle-dashboards {
        width: 100%;
        margin: 0;
        top: 80px;
        padding: 10px 20px 35px;
        height: calc(100dvh - 77px);
        z-index: 2;
    }

    .account-dashboards--search {
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 900px) and (max-width: 1800px) {
    .account-dashboards--content {
        grid-template-columns: repeat(5, calc(20% - 10px)) !important;
        grid-template-rows: repeat(2, 48%) !important;
        gap: 10px !important;
    }

    .microArticle-dashboards {
        margin: 118px 15px 20px;
        padding: 10px 20px 35px;
        height: calc(100dvh - 120px);
        z-index: 2;
    }

    /*.microArticle-dashboards*/
    /*:global(ul.MuiPagination-ul li:not(:first-child, :last-child)) {*/
    /*    height: 25px;*/
    /*}*/

    /*.account-dashboards--pagination .small {*/
    /*    display: unset;*/
    /*}*/

    /*.account-dashboards--pagination .medium {*/
    /*    display: none;*/
    /*}*/
}
