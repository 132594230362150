@tailwind base;
@tailwind components;
@tailwind utilities;

*:focus-visible {
    outline: none;
    box-shadow: none;
    border-color: transparent;
}
*:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
}
