.fontStyles {
    font-size: 12px !important;
    font-family: 'Noto Sans Armenian', sans-serif !important;
}

.microArticle_form .ant-form-item {
    margin-bottom: 17px;
}

/* Skeleton loading styles */
.skeletonContainer {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.headerSkeletonWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
}

.ant-input-data-count {
    margin-right: 10px !important;
    color: #2C476C50 !important;
}

.headerSkeleton {
    display: flex;
    align-items: center;
    gap: 8px;
}

.headerSkeletonInitials {
    border-radius: 20px;
    max-width: 31px;
    max-height: 23px;
    min-width: 31px;
    min-height: 23px;
}

.headerSkeletonInitials > .ant-skeleton-avatar.ant-skeleton-avatar-square {
    border-radius: 20px;
    max-width: 31px;
    max-height: 23px;
    min-width: 31px;
    min-height: 23px;
}

.carouselSkeleton {
    width: 100%;
    height: 200px;
}

.carouselSkeleton > .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
    width: 100% !important;
    height: 100% !important;
}

.descriptionSkeleton {
    width: 100%;
    height: 100px;
}

.buttonsSkeleton {
    display: flex;
    gap: 8px;
}

.buttonsSkeleton > .skeletonButton {
    max-width: 35px;
    width: 35px;
    height: 35px;
}

.buttonsSkeleton > .skeletonButton > span {
    max-width: 35px !important;
    min-width: 35px !important;
    width: 35px !important;
    max-height: 35px !important;
    min-height: 35px !important;
    height: 35px !important;
}

.carouselSkeleton {
    width: 100%;
    height: 290px;
}

.carouselSkeleton .ant-skeleton-image {
    width: 100%;
    height: 100%;
}

.descriptionSkeleton .ant-skeleton-paragraph > li {
    width: 80%;
}
