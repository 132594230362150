.map-boby .header__burger {
  position: absolute;
  top: 0;
  left: 14px;
  background-color: #fff;
  width: 42px;
  font-size: 21px;
  z-index: 4;
  height: 42px;
  margin-top: max(calc(100vh/216.2),5px);
  margin-left: max(calc(100vw/384),5px);
  border-radius: 10px;
  box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
  font-weight: 600;
  transition: left ease-in-out 0.4s;
}
.map-boby .presentation__button {
  position: absolute;
  top: 0;
  left: 54px;
  background-color: #fff;
  width: 42px;
  font-size: 21px;
  z-index: 4;
  height: 42px;
  margin-top: max(calc(100vh/216.2),5px);
  margin-left: max(calc(100vw/384),5px);
  border-radius: 10px;
  box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
  font-weight: 600;
}

.presentation__button.active {
  background-color: #2C476C;
}

.presentation__actions {
  position: absolute;
  max-width: 500px;
  left: 100px;
}

.presentation__actions button {
  background-color: #fff;
  width: 42px;
  font-size: 21px;
  z-index: 4;
  height: 42px;
  margin-top: max(calc(100vh/216.2),5px);
  margin-left: max(calc(100vw/384),5px);
  border-radius: 10px;
  box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
  font-weight: 600;
}

.presentation__actions button div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}

.presentation__popup {
  position: absolute;
  max-width: 500px;
  background: #fff;
  left: 110px;
  margin-top: max(calc(100vh/216.2),5px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
}

.presentation__popup button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.presentation__popup p {
  color: #6E6B7B;
  font-family: Mardoto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px
}

.presentation__button div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}

.account .header__burger .presentation__button .presentation__popup{
  margin-top: max(calc(100vh/56),5px);
  margin-left: max(calc(100vw/100),5px);
}
.header__burger.active {
  left: 335px;
  background-color: var(--main-color);
  font-size: 0;
}
.header__burger.active::before,
.header__burger.active::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 2px;
  background-color: #fff;
}
.header__burger.active::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.header__burger.active::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.header__nav {
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 14;
  height: 100%;
  width: 325px;
  background-color: var(--main-color);
  padding: 0 20px;
}

.header__nav.active {
  left: 0;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
  /*.account  .header__burger  {*/
  /*  transform: scale(0.8);*/
  /*  margin: 0 0 0 0 ;*/
  /*}*/
  .account  .header__nav{
    width: 260px;
  }
  .account  .header__burger.active{
    left: 260px;
  }
}
@media (min-width: 1025px){
  .map-boby .header__burger {
    left: 0;
  }
}
@media (max-width: 1024px) {
  .map-boby .header__burger {
    left: 11.5px;
    width: 36px;
    height: 36px;
    top: 25px;
  }
  .map-boby .presentation__button {
    left: 62.5px;
    width: 36px;
    height: 36px;
    top: 25px;
    display: none;
  }
  .presentation__popup {
    top: 25px;
  }
  .account  .header__burger .presentation__button {
    width: 50px;
    height: 50px;
    left: 13px;
  }

  .presentation__actions {
    position: absolute;
    max-width: 500px;
    left: 105px;
    top: 25px;
    display: none;
  }

  .presentation__actions button {
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 1024px) {

  .header__burger_wrapper .header__burger {
    position: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    min-height: 44px;
    height: 44px;
    width: 44px;
    max-width: 44px;
    max-height: 44px;
    border-radius: 5px;
    margin-top: 0;
    margin-left: 0;
    box-shadow: 0px 7px 26px 0px #2C476C1A;
  }

  .header__burger_wrapper {
    min-height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  /*min-height: 72px;*/
  /*width: 100%;*/
  /*height: fit-content;*/
}

@media (max-width: 512px) {
  .map-boby .header__burger .presentation__button{
    left: 10.5px;
    width: 37px;
    height: 37px;
    top: 29px;
  }

  .presentation__actions {
    position: absolute;
    max-width: 500px;
    left: 105px;
    top: 25px;
  }

  .presentation__actions button {
    width: 36px;
    height: 36px;
  }

  .header__burger.active {
    margin-left: 12px;
  }
}
