.sprite-icon {
  width: var(--width);
  height: var(--height);
  max-height: 28px;
  max-width: 28px;
}
.centered {
  position: relative;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
}
.sprite-icon:after {
  content: '' !important;
  background-image: var(--image-src);
  width: var(--width);
  height: var(--height);
  background-position-x: var(--position-x);
  background-position-y: var(--position-y);
  background-size: auto;
  background-repeat: no-repeat;
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

.sprite-svg {
  display: inline-flex; /* Ensure it takes only necessary space */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent overflow if needed */
  max-height: 28px;
  max-width: 28px;
}

@media (max-width: 1024px) {
  .sprite-icon {
    scale: 0.8;
  }
}
