:root {
  --presentation-bar-size: 42px;
  --primary-color: #036147;
}

.presentation-left-bar--container {
  position: absolute;
  top: 0;
  left: 54px;
  width: var(--presentation-bar-size);
  font-size: 21px;
  z-index: 4;
  height: var(--presentation-bar-size);
  margin-top: max(calc(100vh / 216.2), 5px);
  margin-left: max(calc(100vw / 384), 5px);
}

.presentation-btn {
  background: #fff;
  width: 42px !important;
  height: 42px;
}

.presentation-btn.active {
  background: var(--primary-color);
}

.presentation-list--header {
  background: var(--primary-color);
  padding: 10px 20px;
  height: var(--presentation-bar-size);
  color: #fff;
  display: flex;
  align-items: center;
}

.presentation-list--header svg {
  width: 20px;
  height: 20px;
}

.presentation-list--header .presentation-list--header-back-button {
  cursor: pointer;
  margin-right: 1px;
  height: 20px;
}

.presentation-list {
  background: #fff;
  padding: 4px;
  margin: 0 5px;
  width: 385px;
  max-height: 385px;
  overflow-y: auto;
}

.presentation-slide-content {
  overflow-y: auto;
  width: 385px;
  min-width: 100%;
}

.presentation-slide-content .presentation-list--item,
.presentation-slide-content
  .presentation-list--item
  + .presentation-patum--editor {
  margin: 10px 10px 2px;
}

.presentation-slide-content--body {
  padding: 10px;
}

.presentation-slide--item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  transition: background 0.1s;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
}

.presentation-slide-content--title {
  font-size: 14px;
  padding: 15px 0;
  display: block;
}

.presentation-slide--footer {
  background-color: #2c476c1a;
  cursor: pointer;
  border-top: 1px solid #2c476c1a;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.presentation-list--item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  transition: background 0.1s;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
}

.presentation-list--item.active,
.presentation-list--item:hover,
.presentation-slide-content .presentation-list--item {
  background: #2c476c1a;
}

.presentation-list--item .presentation-list--item--icons {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.1s;
}

.presentation-article-selector--items {
  padding: 15px;
}

.presentation-article-selector--item {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 2px 10px;
  transition: background 0.1s;
  border-radius: 30px;
  cursor: pointer;
  width: 90%;
}

.presentation-article-selector--item.presentation-article-selector--item-selected
  .sprite-icon {
  filter: invert(1) brightness(100);
}

.presentation-article-selector--item img {
  width: 30px;
  height: 30px;
  max-width: 100%;
}

.presentation-article-selector--item:not(
    .presentation-article-selector--item-selected
  ):hover {
  background: #2c476c1a;
}

.presentation-article-selector--footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.presentation-list--item.active .presentation-list--item--icons,
.presentation-list--item:hover .presentation-list--item--icons,
.presentation-slide-content
  .presentation-list--item
  .presentation-list--item--icons {
  opacity: 1;
}

.presentation-list--item--icons svg,
.presentation-slide-content svg:not(.presentation-slide--footer svg), .presentation-slide-content svg:not(.presentation-slide--item svg) {
  width: 35px;
  height: 35px;
  max-width: 100%;
}

div.presentation-list--item--icons svg.dots-svg-icon {
  height: 16px;
}

.presentation-article-selector {
  /*padding: 20px;*/
}

.presentation-article-selector-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
}

.presentation-article-selector--filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.presentation-article-selector-header .close-icon {
  cursor: pointer;
  position: absolute;
  right: 0px;
  /*top: 20px;*/
}

.app-dropdown.presentation-article-selector--dropdown .ant-dropdown-menu-item {
  padding-left: 30px;
}

.app-dropdown.ant-dropdown .ant-dropdown-menu-item-disabled {
   opacity: 0.5;
}

.app-dropdown.presentation-article-selector--dropdown
  .ant-dropdown-menu-item-selected {
  background-color: transparent;
  font-weight: 600;
  color: #1a1a1a;
}

.app-dropdown.presentation-article-selector--dropdown
  .ant-dropdown-menu-item-selected::before {
  position: absolute;
  left: 8px;
  top: 4px;
  /*width: 4px;*/
  height: 100%;
  content: '\2713';
  display: inline-block;
  color: #28ca28;
  padding: 0 6px 0 0;
  border-radius: 0 4px 4px 0;
}

.search-input {
  border-bottom: 1px solid #bdc5d1;
  border-radius: 0;
}

/* GLOBAL */

.app-dropdown.ant-dropdown .ant-dropdown-menu {
  border-radius: 12px;
}

.app-dropdown.ant-dropdown .ant-dropdown-menu-item {
  gap: 10px;
  border-radius: 12px;
  align-items: center;
  color: #2c476c;
}

.app-dropdown.ant-dropdown
  .ant-dropdown-menu-submenu
  .ant-dropdown-menu-submenu-title {
  gap: 10px;
  align-items: center;
}

.app-dropdown.ant-dropdown .ant-dropdown-menu-item-icon {
  margin-inline-end: 0;
}

.app-dropdown.ant-dropdown .ant-dropdown-menu-item:hover,
.app-dropdown.ant-dropdown + div .ant-dropdown-menu-item:hover {
  background-color: #2c476c1a;
}

.app-dropdown .patum-dropdown--item {
  position: relative;
  overflow: visible;
}

.app-dropdown .patum-dropdown--item .patum-pt--overview {
  display: none;
  word-break: break-all;
  transition: all 0.2s;
}

.app-dropdown
  .patum-dropdown--item-menu.ant-dropdown-menu-submenu-open
  .patum-pt--overview {
  position: absolute;
  display: block;
  /*content:"Ճանաչել զիմաստութիւն եւ զխրատ, իմանալ զբանս հանճարոյ";*/
  left: 235px;
  width: 220px;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  height: fit-content;
  padding: 10px;
  border-radius: 8px;
  color: #2c476c;
}

.app-dropdown
  .ant-dropdown-menu-submenu-title {
  color: #2c476c !important;
}

.app-dropdown
  ~ div
  .ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item {
  padding-left: 24px;
  color: #2c476c;
  /*background-color: #2C476C1A;*/
  /*color:inherit;*/
}

.app-dropdown
  ~ div
  .ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
  opacity: 0.6;
}

.app-dropdown
  ~ div
  .ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item-selected {
  background-color: transparent;
  color: #2c476c;
  padding-left: 24px;
}

.app-dropdown
  ~ div
  .ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item-selected::before {
  position: absolute;
  left: 8px;
  top: 4px;
  /*width: 4px;*/
  height: 100%;
  content: '\2713';
  display: inline-block;
  color: #28ca28;
  padding: 0 6px 0 0;
  border-radius: 0 4px 4px 0;
}

.app-dropdown [stroke='white'] {
  stroke: #1a1a1a;
}

.app-dropdown--selector, .app-dropdown--selector .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #2c476c;
}

/* MODAL */

.ant-modal.app-modal.no-padding .ant-modal-content {
  padding: 0;
}

.ant-modal.app-modal .ant-form-item .ant-form-item-label > label,
.ant-modal.app-modal .ant-input {
  color: #2c476c;
}

.ant-modal.app-modal .ant-modal-title {
  color: #2c476c;
  letter-spacing: 4px;
}

.ant-dropdown.dropdown-selector:not(.ant-dropdown-hidden) {
  /*transform: translateY(-20px);*/
  inset: 40px auto auto 0 !important;
}

.ant-dropdown.dropdown-selector > .ant-dropdown-menu {
  padding-top: 10px;
}

.ant-dropdown.dropdown-selector .ant-dropdown-menu-item {
  padding-left: 30px;
}

.ant-dropdown.dropdown-selector .ant-dropdown-menu-item-selected {
  background-color: transparent;
  font-weight: 600;
  color: #2c476c;
}

.ant-dropdown.dropdown-selector .ant-dropdown-menu-item-selected::before {
  position: absolute;
  left: 8px;
  top: 4px;
  /*width: 4px;*/
  height: 100%;
  content: '\2713';
  display: inline-block;
  color: #28ca28;
  padding: 0 6px 0 0;
  border-radius: 0 4px 4px 0;
}

.formItemChangedAsterisk {
}

.formItemChangedAsterisk
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: '*';
  margin-right: 4px;
  color: red;
}

.formItemChangedAsterisk
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  content: none;
}

/*QUIL EDITOR*/

.quill-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 0;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.quill-container.quill-focused {
  padding-top:38px;
  transition: all 0.3s;
}

.quill-container.quill-focused .ql-toolbar {
  /*border-bottom: 1px solid #ccc;*/
  /*height: 42px;*/
  background: #eaedf0;
  overflow: hidden;
  /*padding: 8px !important;*/
  top:0
}

.quill-container .ql-toolbar {
  border: none !important;
  background: transparent;
  /*height: 0;*/
  /*padding: 0 !important;*/
  overflow: hidden;
  transition: all 0.3s;
  position: absolute;
  top: -44px;
  left: 0;
  right: 0;
  z-index: 1;

}

.readably--quill .ql-editor ul, .readably--quill .ql-editor ol  {
  padding-left: 0;
}

.inherit-fontsize--quill .ql-editor , .inherit-fontsize--quill .ql-container {
  font-size: inherit !important;
}

/* General Quill Editor Styling */
.quill-container .ql-editor {
  font-family: 'Noto Sans Armenian', sans-serif !important;
  font-size: 16px;
  /*border: 1px solid #2c476c50; !* Tailwind's gray-400 *!*/
  border-radius: 8px;
  padding: 12px !important;
  line-height: 1.6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  color: #2c476c;
  word-break: break-word;
}

.quill-container .ql-editor ol, .quill-container .ql-editor  ul {
  padding-left: 0px;
}

.quill-container .quill {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 42px);
}

.quill-container .ql-editor a,
.quill-container .ql-editor p {
  color: #2c476c;
}

.quill-container .ql-container.ql-snow {
  border: none;
}

/* Toolbar Customization */
.quill-container .ql-toolbar {
  border: 1px solid #2c476c50;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  background-color: #eaedf0; /* Tailwind's gray-100 */
}

/* Toolbar Button Customization */
.quill-container .ql-toolbar button {
  color: #2c476c; /* Tailwind's gray-600 */
  border-radius: 4px;
  margin: 0 1px;
}

.quill-container .ql-toolbar button .ql-stroke,
.quill-container .ql-toolbar button rect {
  color: #2c476c; /* Tailwind's gray-600 */
  border-radius: 4px;
  stroke: #2c476c !important;
}

.quill-container .ql-toolbar button .ql-fill {
  fill: #2c476c !important;
}

.quill-container .ql-toolbar button:hover {
  background-color: #e5e7eb;
  color: #2c476c;
}

.quill-container .ql-toolbar .ql-link {
  position: relative;
  margin: 0 5px;
}

.quill-container .ql-toolbar .ql-link::before,
.quill-container .ql-toolbar .ql-link::after {
  position: absolute;
  left: -3px;
  top: 0;
  width: 1px;
  height: 100%;
  content: '';
  background-color: #2c476c50;
}

.quill-container .ql-toolbar .ql-link::before {
  right: -3px;
  left: auto;
}

.quill-container .ql-toolbar .ql-active {
  color: #1f2937 !important;
  background-color: #dadada !important;
  border-radius: 4px;
  /*font-weight: 600 !important;*/
}

/* Focused editor style */
.quill-container .ql-editor:focus {
  outline: none;
  border-color: rgba(16, 69, 156, 0.37); /* Tailwind's blue-500 */
}

.quill-container .ql-editor.ql-blank::before {
  color: #bdc5d1;
  font-style: normal;
  font-size: 14px;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
[data-is-deleted="true"] {
  opacity: 0.6;
}