/* src/components/MediaRenderer.module.css */

.mediaContainer {
    position: relative;
    width: 100%;
    background-color: #2C476C1A;
    overflow: hidden; /* Ensures the overlay does not exceed the container */
}

.media {
    width: 100%;
    max-height: 320px;
    min-height: 320px;
    object-fit: contain;
    display: block; /* Removes potential gaps below the image */
}

.media.video {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 320px;
    max-height: 320px;
    min-height: 320px;
}

.media.iframe {
    /* Adjust iframe styling if needed */
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: all; /* Blocks interaction with underlying elements */
    z-index: 2; /* Ensure the overlay is above media */
}

/* New Styles Added Below */

.mediaContainerFull {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.mediaContainerFull .media {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mediaContainerFull .media.video,
.mediaContainerFull .media.iframe {
    width: 100%;
    height: 100%;
}

@media (max-width: 1025px) {
    .media {
        width: 100%;
        max-height: 350px;
        min-height: 350px;
        object-fit: contain;
        display: block; /* Removes potential gaps below the image */
    }
}

@media (max-width: 769px) {
    .media {
        width: 100%;
        max-height: 300px;
        min-height: 300px;
        object-fit: contain;
        display: block; /* Removes potential gaps below the image */
    }
}

@media (max-width: 426px) {
    .media {
        width: 100%;
        max-height: 250px;
        min-height: 250px;
        object-fit: contain;
        display: block; /* Removes potential gaps below the image */
    }
}

@media (max-width: 391px) {
    .media {
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        object-fit: contain;
        display: block; /* Removes potential gaps below the image */
    }
}
