#svgsvg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #7396a6;
    z-index: 99;
    right: 0;
    top: 0;
}

#world-map path {
    fill: none;
    stroke: url("#gradient");
    stroke-width: 2px;
    will-change: stroke-dashoffset, opacity;
}

.animate-path {
    animation:
            draw-path 10s cubic-bezier(0.5, 0, 0.6, 1) infinite,
            opacity-pulse 10s ease-in-out infinite;
}

@keyframes draw-path {
    0%, 100% {
        stroke-dashoffset: var(--dashoffset);
    }
    25%, 75% {
        stroke-dashoffset: 0;
    }
}

@keyframes opacity-pulse {
    0%, 100% { opacity: 0.6; }
    25%, 75% { opacity: 1; }
}


@media (max-width: 1024px) {
    #svgsvg {
        transform: scale(1) translateZ(0);
    }
}

@media (max-width: 768px) {
    #svgsvg {
        transform: scale(1.3) translateZ(0);
    }
}

@media (max-width: 474px) {
    #svgsvg {
        transform: scale(1.8) translateZ(0);
    }
}
