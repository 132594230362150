.portal-content {
    width: min-content;
    max-height: min-content;
}

@media (max-width: 1540px) {
    /* SOME ITEMS IN PORTAL CONTENT ARE TRANSFORM: SCALE() -ED, DO NOT TOUCH THIS */
    .portal-content {
        pointer-events: none !important;
    }

    .portal-content > * {
        pointer-events: all !important;
    }
}
