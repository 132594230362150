.dot-container {
    min-height: 12px;
    position: relative;
}

.dots-container--body {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    padding:10px
}

.dots-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
    scrollbar-width: none;
}

.dots-scroll::-webkit-scrollbar {
    display: none;
}

.dot {
    transition: all 0.5s ease;
    cursor: pointer;
}
