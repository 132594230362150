.pain_items {
    position: relative;
}

.button_active {
    background-color: #2c476c;
}

.button_active img {
    filter: brightness(0) invert(1);
}

.mapbox-popUp-container-comment-box {
    height: 160px;
    left: 175px;
    top: -15px;
    transition: ease-in-out 0.1s;
}

.popUp-container {
    width: 320px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 4px 24px 0px #292E3233;
}

.popUp-container > .color-picker-popup {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    position: absolute;
    width: 180px;
    height: 30px;
    bottom: calc(100% + 5px);
    left: 0;
    background-color: #3064ab;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #292E3233;
    cursor: pointer;
}

.color-picker-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.comment-box-popup-container_color-picker > .current-picked-color-comment-box {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.comment-box-available-color {
    display: flex;
    justify-content: center;
    align-items: center;
}

.comment-box-available-color > svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.comment-box-popup-container_color-picker > .current-picked-color-comment-box > svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.painter_tools_color_data_item > .available-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.popUp-container > .comment_box_tools_color_item_container {
    padding: 10px 12px;
    background: #fff;
    position: absolute;
    width: 216px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    bottom: 210px;
    right: 132px;
    box-shadow: 0 4px 24px 0 #292E3233;
}

.comment_box_tools_color_item {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.comment_box_tools_color_item:hover {
    border: 2px solid #fff;
}

.popUp-container > .comment_box_tools_color_item_container > span {
    width: 22px;
    height: 22px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 89%;
    top: 98%;
    background: #fff;
}

.popUp-container > .edit-popup-header {
    width: 100%;
}

.popUp-container > .edit-popup-header > .popUp-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    border-radius: 8px 8px 0px 0px;
    background-color: #3064ab;
}

.popUp-container > .popUp-editor {
    height: 130px;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
}

.popUp-container > .popUp-editor > .popUp-counter {
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.popUp-container > .popUp-editor > .popUp-counter > span {
    padding-right: 5px;
}

.popUp-container > .popUp-editor > textarea {
    width: 100%;
    font-family: "Noto Sans Armenian", "sans-serif";
    height: calc(100% - 24px);
    line-height: 1.15rem;
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: 0 0 8px 8px;
    resize: none;
    padding: 0 8px;
    margin-bottom: 8px;
    background-color: #fff;
}

/*.popUp-container > i {*/
/*    position: absolute;*/
/*    top: 200%;*/
/*    left: 10%;*/
/*    margin-left: -12px;*/
/*    width: 34px;*/
/*    height: 17px;*/
/*    overflow: hidden;*/
/*}*/

/*.popUp-container > i::before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    width: 17px;*/
/*    height: 17px;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%) rotate(45deg);*/
/*    background-color: #FFF;*/
/*}*/

textarea::-webkit-resizer {
    display: none;
}

.popUp-actions span {
    width: 26px;
    cursor: pointer;
    margin: 0px 5px;
}

.popUp-save > img {
    opacity: .5;
    cursor: default;
}

.popUp-save .active-save-icon {
    opacity: 1;
    cursor: pointer;
}

.comment-toolbox-marker {
    display: block;
    width: 40px;
    height: 40px;
}

.mapbox-popUp-container-comment-box > .mapboxgl-popup-tip {
    display: none !important;
}

.comment-toolbox-marker.mapboxgl-marker:hover,
.comment-toolbox-marker.mapboxgl-marker:active,
.mapboxgl-popup
{
    z-index: 9999999;
}

.comment-toolbox-marker > svg {
    height: 40px;
    width: 40px;
}

.hover-popUp-container {
    position: absolute;
    bottom: 45px;
    left: 36px;
    max-width: 320px;
    display: none;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(-15px);
}

.hover-popUp-container.notHovered {
    display:none;
    visibility: initial;
    opacity: 0;
    transform: translateY(0px);
    transition: all .7s ease-in-out;
    animation: reverseAnim .7s ease 0s 1 normal forwards;
}

.hover-popUp-container.hovered {
    display: block;
    opacity: 1;
    transform: translateY(0px);
    transition: all .7s ease-in-out;
    animation: myAnim .7s ease 0s 1 normal forwards;
}

@keyframes myAnim {
    0% {
        display: block;
        visibility: initial;
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes reverseAnim {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-15px);
        display: none;
        visibility: hidden;
    }
}

.hover-popUp-container > p {
    width: 100%;
}

.hover-popUp-container > .hover-popUp-text {
    /*max-height: 145px;*/
    font-family: 'Noto Sans Armenian', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: #6E6B7B;
    border-radius: 30px 30px 30px 0px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #292E3233;
    word-break: break-word;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    /*max-height: 145px; !* I needed this to get it to work *!*/
    overflow: hidden;
    width: max-content;
    max-width: 300px;
}

@media (max-width: 512px) {
    .popUp-container {
        width: 200px;
    }

    .hover-popUp-container {
        max-width: 210px !important;
    }

    .hover-popUp-container > .hover-popUp-text > .hover-popUp-text-span {
        max-width: 190px !important;
    }

    .popUp-container > .comment_box_tools_color_item_container {
        right: 12px;
    }
}


/* NEED */
/*.hover-popUp-container > .hover-popUp-text {*/
/*    min-width: 320px;*/
/*    min-height: 50px;*/
/*    max-height: 67px;*/
/*    font-family: 'Noto Sans Armenian', sans-serif;*/
/*    font-weight: 500;*/
/*    font-size: 1rem;*/
/*    color: #6E6B7B;*/
/*    line-height: 1.5;*/
/*    border-radius: 30px 30px 30px 0px;*/
/*    padding: 15px;*/
/*    background-color: #fff;*/
/*    display: -webkit-box;*/
/*    -webkit-line-clamp: 2;*/
/*    -webkit-box-orient: vertical;*/
/*    overflow: hidden;*/
/*    word-wrap: break-word;*/
/*    box-shadow: 0px 4px 24px 0px #292E3233;*/
/*}*/


