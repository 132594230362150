.account-dashboards {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    /* background-color: rgba(216, 216, 216, .4); */
    padding: 130px 140px 90px;
    overflow: auto;
    display: flex;
    align-items: stretch;
    min-height: 100%;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .account {
        /*transform: scale(0.8);*/
        /*zoom: 80%*/
    }
}
.top-btns {
    align-items: stretch;
    display: flex;
    gap: 1px;
    justify-content: space-between;
    /*padding-left: 80px;*/
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9 !important;
}
/*.top-btns>div{*/
/*    display: flex;*/
/*    gap: 10px;*/
/*    margin: max(calc(100vh/56),5px);*/
/*}*/
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .top-btns>div  {
        margin:0;
    }
    .account-dashboards{
        padding: 120px 105px 50px;
    }
}
