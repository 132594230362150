.account-dashboards--pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.main-wrapper-microArticleBoard ul.MuiPagination-ul li:first-child,ul.MuiPagination-ul li:last-child {
    background-color: #F3F2F7;
    border-radius: 50%;
    margin: 0px 10px;
}
.main-wrapper-microArticleBoard ul.MuiPagination-ul li:nth-child(2) {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    -moz-border-radius-bottomleft: 20px;
}

.main-wrapper-microArticleBoard ul.MuiPagination-ul li:nth-last-child(-2n+2) {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    -moz-border-radius-bottomright: 20px;
    -moz-border-radius-topright: 20px;
}
.main-wrapper-microArticleBoard ul.MuiPagination-ul li:not(:first-child,:last-child) {
    background-color: #D9D9D9;
    height: 30px;
}
.main-wrapper-microArticleBoard ul.MuiPagination-ul li button{
    margin: 0px;
    border: none;
}
/*.main-wrapper-microArticleBoard ul.MuiPagination-ul li:not(:first-child,:last-child) button{*/
/*    top: -1px;*/
/*}*/
.main-wrapper-microArticleBoard ul.MuiPagination-ul li button.Mui-selected{
    background-color: #3985EC;
    border-radius: 50%;
    color: #fff;
}
.account-dashboards--pagination .small{
    display: none;
}
.account-dashboards--pagination .medium{
    display: unset;
}

@media  screen and (min-width: 900px) and (max-width: 1800px) {
    /*.main-wrapper-microArticleBoard ul.MuiPagination-ul li:not(:first-child,:last-child){*/
    /*    height: 25px;*/
    /*}*/
    .account-dashboards--pagination .small{
        display: unset;
    }
    .account-dashboards--pagination .medium{
        display: none;
    }
}

@media screen and (max-height: 800px) and (max-width: 1024px) {
    .account-dashboards--pagination {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #2c476c;
        margin-bottom: 5px;
    }
}
